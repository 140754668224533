import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allElectricianAgents: {},
    agent: {},
    eleAgentDevicesForTable: {},
    eleAgentDevicesIsLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_AGENTS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_AGENTS_SUCCESS:
            return { ...state, isLoading: false, allElectricianAgents: action.payload || {} }

        case actionsTypes.GET_ALL_AGENTS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_INSTALLER_AGENT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ADD_INSTALLER_AGENT_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_INSTALLER_AGENT_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_INSTALLER_AGENT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_INSTALLER_AGENT_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_INSTALLER_AGENT_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_INSTALLER_AGENT_DETAIL_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_INSTALLER_AGENT_DETAIL_BY_ID_SUCCESS:
            return { ...state, isLoading: false, agent: action.payload || {} }

        case actionsTypes.GET_INSTALLER_AGENT_DETAIL_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_INSTALLER_AGENT_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_INSTALLER_AGENT_SUCCESS:
            return { ...state, isLoading: false, agent: action.payload || {} }

        case actionsTypes.DELETE_INSTALLER_AGENT_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, eleAgentDevicesIsLoading: true }

        case actionsTypes.GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, eleAgentDevicesIsLoading: false, eleAgentDevicesForTable: action.payload || {} }

        case actionsTypes.GET_ELEC_PANEL_AGENT_DEVICES_FOR_TABLE_FAILED:
            return { ...state, eleAgentDevicesIsLoading: false }


        case actionsTypes.CLEAR_ELECTRICIAN_AGENT_DATA:
            return { ...state, ...initialState }



        default:
            return state
    }
}