
import * as actionsTypes from './actions'
let initialState = {
    isLoading: false,
    allAggregators: {}
}


export default function ruleReducer(state = { ...initialState }, action) {

    switch (action.type) {

        case actionsTypes.GET_ALL_AGGREGATORS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_AGGREGATORS_SUCCESS:
            return { ...state, isLoading: false, allAggregators: action.payload || {} }

        case actionsTypes.GET_ALL_AGGREGATORS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_NEW_AGGREGATOR_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ADD_NEW_AGGREGATOR_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_NEW_AGGREGATOR_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_AGGREGATOR_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_AGGREGATOR_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_AGGREGATOR_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_AGGREGATOR_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_AGGREGATOR_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_AGGREGATOR_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_AGGREGATOR_STORE:
            return { ...state, ...initialState }

        default:
            return state

    }

}