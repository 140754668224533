import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allSmokeAlarmDevices: {},
    singleSmokeAlarmlinkDevice: {},
    // smokeAlarmDevicesForTable: {},
    // smokeAlarmDevicesIsLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_SMOKEALARM_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_SMOKEALARM_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, allSmokeAlarmDevices: action.payload || {} }

        case actionsTypes.GET_ALL_SMOKEALARM_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_SINGLE_SMOKEALARM_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_SINGLE_SMOKEALARM_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, singleSmokeAlarmlinkDevice: action.payload || {} }

        case actionsTypes.GET_SINGLE_SMOKEALARM_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        // case actionsTypes.GET_SMOKE_ALARM_TABLE_REQUEST:
        //     return { ...state, smokeAlarmDevicesIsLoading: true }

        // case actionsTypes.GET_SMOKE_ALARM_TABLE_SUCCESS:
        //     return { ...state, smokeAlarmDevicesIsLoading: false, smokeAlarmDevicesForTable: action.payload }

        // case actionsTypes.GET_SMOKE_ALARM_TABLE_FAILED:
        //     return { ...state, smokeAlarmDevicesIsLoading: false }

        case actionsTypes.CLEAR_ALL_SAFELINK_DEVICE_DATA:
            return { ...state, ...initialState }

        default:
            return state
    }
}
