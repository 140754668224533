import * as actionsTypes from './actions';

const initialState = {
    getUserTotalVersionLoding: false,
    userBaseDeviceLoading: false,
    userWithWeeklyReportsLoading: false,
    userWithAutoSyncOnLoading: false,
    userWithUnsubscribeDataLoading: false,
    userWithUnsubscribeWeeklyReportsLoading: false,
    userSyncDataWithFiltersLoading: false,
    averageBatteryLifeLoding: false,
    weeklyReportsDataLoading: false,
    locationPreferenceLoading: false,
    versionListLoading: false,
    firmWareVersionLoading: false,
    versionLists: [],
}


export default function reducer(state = { ...initialState }, action) {

    switch (action.type) {

        case actionsTypes.GET_USER_APP_VERSION_REQUEST:
            return { ...state, getUserTotalVersionLoding: true }

        case actionsTypes.GET_USER_APP_VERSION_SUCCESS:
            return { ...state, getUserTotalVersionLoding: false, getUserCountAndVersion: action.payload }

        case actionsTypes.GET_USER_APP_VERSION_FAILED:
            return { ...state, getUserTotalVersionLoding: false }

        case actionsTypes.GET_USER_BASE_DEVICE_REQUEST:
            return { ...state, userBaseDeviceLoading: true }

        case actionsTypes.GET_USER_BASE_DEVICE_SUCCESS:
            return { ...state, userBaseDeviceLoading: false }

        case actionsTypes.GET_USER_BASE_DEVICE_FAILED:
            return { ...state, userBaseDeviceLoading: false }

        case actionsTypes.USER_WITH_TOTAL_WEEKLY_REPORTS_REQUEST:
            return { ...state, userWithWeeklyReportsLoading: true }

        case actionsTypes.USER_WITH_TOTAL_WEEKLY_REPORTS_SUCCESS:
            return { ...state, userWithWeeklyReportsLoading: false }

        case actionsTypes.USER_WITH_TOTAL_WEEKLY_REPORTS_FAILED:
            return { ...state, userWithWeeklyReportsLoading: false }

        case actionsTypes.GET_AUTO_SYNC_ON_USER_DATA_REQUEST:
            return { ...state, userWithAutoSyncOnLoading: true }

        case actionsTypes.GET_AUTO_SYNC_ON_USER_DATA_SUCCESS:
            return { ...state, userWithAutoSyncOnLoading: false, }

        case actionsTypes.GET_AUTO_SYNC_ON_USER_DATA_FAILED:
            return { ...state, userWithAutoSyncOnLoading: false }

        case actionsTypes.GET_UNSUBSCRIBE_USER_DATA_REQUEST:
            return { ...state, userWithUnsubscribeDataLoading: true }

        case actionsTypes.GET_UNSUBSCRIBE_USER_DATA_SUCCESS:
            return { ...state, userWithUnsubscribeDataLoading: false, }

        case actionsTypes.GET_UNSUBSCRIBE_USER_DATA_FAILED:
            return { ...state, userWithUnsubscribeDataLoading: false }

        case actionsTypes.GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_REQUEST:
            return { ...state, userWithUnsubscribeWeeklyReportsLoading: true }

        case actionsTypes.GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_SUCCESS:
            return { ...state, userWithUnsubscribeWeeklyReportsLoading: false, }

        case actionsTypes.GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_FAILED:
            return { ...state, userWithUnsubscribeWeeklyReportsLoading: false }

        case actionsTypes.GET_USER_SYNC_DATA_WITH_FILTERS_REQUEST:
            return { ...state, userSyncDataWithFiltersLoading: true }

        case actionsTypes.GET_USER_SYNC_DATA_WITH_FILTERS_SUCCESS:
            return { ...state, userSyncDataWithFiltersLoading: false, }

        case actionsTypes.GET_USER_SYNC_DATA_WITH_FILTERS_FAILED:
            return { ...state, userSyncDataWithFiltersLoading: false }

        case actionsTypes.GET_AVERAGE_BATTERY_LIFE_REQUEST:
            return { ...state, averageBatteryLifeLoding: true }

        case actionsTypes.GET_AVERAGE_BATTERY_LIFE_SUCCESS:
            return { ...state, averageBatteryLifeLoding: false, }

        case actionsTypes.GET_AVERAGE_BATTERY_LIFE_FAILED:
            return { ...state, averageBatteryLifeLoding: false }

        case actionsTypes.GET_WEEKLY_REPORTS_REQUEST:
            return { ...state, weeklyReportsDataLoading: true }

        case actionsTypes.GET_WEEKLY_REPORTS_SUCCESS:
            return { ...state, weeklyReportsDataLoading: false, }

        case actionsTypes.GET_WEEKLY_REPORTS_FAILED:
            return { ...state, weeklyReportsDataLoading: false }

        case actionsTypes.GET_LOCATION_PREFERENCE_REQUEST:
            return { ...state, locationPreferenceLoading: true }

        case actionsTypes.GET_LOCATION_PREFERENCE_SUCCESS:
            return { ...state, locationPreferenceLoading: false, }

        case actionsTypes.GET_LOCATION_PREFERENCE_FAILED:
            return { ...state, locationPreferenceLoading: false }

        case actionsTypes.GET_APP_VERSION_LIST_REQUEST:
            return { ...state, versionListLoading: true }

        case actionsTypes.GET_APP_VERSION_LIST_SUCCESS:
            return { ...state, versionListLoading: false, versionLists: action.payload }

        case actionsTypes.GET_APP_VERSION_LIST_FAILED:
            return { ...state, versionListLoading: false }

        case actionsTypes.CLEAR_ALL_DEVICE_DATA:
            return { ...state, ...initialState }

        default:
            return state
    }

}