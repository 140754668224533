import { isEmpty } from "lodash";

export function addRule(data) {
    let errors = {}

    if (!data.from) {
        errors.from = 'Please select Current Version.'
    }

    if (!data.to) {
        errors.to = 'Please select Upgrade Version.'
    }

    if (!data.name) {
        errors.name = 'Name is required.'
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}