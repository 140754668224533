
import { isEmpty } from "lodash";


export function validateAdminDevice(data) {
    let errors = {}

    if (!data.impulse_rate) {
        errors.impulse_rate = "Impulse Rate is required"
    }

    if (data.impulse_rate < 0) {
        errors.impulse_rate = "Impulse rate should be greater than 0"
    }

    if (data?.impulse_rate?.length && data?.impulse_rate_type === "Wh/imp") {

        let val = 1000 / data?.impulse_rate

        if (val.toString().includes(".")) {
            errors.impulse_rate = "Please enter a valid impulse rate";
        }
    }

    // if (!data.NMI) {
    //     errors.NMI = "NMI is required"
    // }

    return {
        isValid: isEmpty(errors),
        errors
    };
}

export function validateDeviceHistoryTariffRates(data) {

    let { tariffDetail, impuseRate, date, updateSection } = data

    let errors = {}

    if (updateSection === "impulseRate") {
        errors = impulseRateValidate(impuseRate, date, errors)
    } else if (updateSection === "tariffRate") {
        errors = tariffRateValidate(tariffDetail, date, errors)
    } else if (updateSection === "both") {
        errors = impulseRateValidate(impuseRate, date, errors)
        errors = tariffRateValidate(tariffDetail, date, errors)
    } else {
        errors.updateSection = "Update on is required"
    }

    return {
        isValid: isEmpty(errors),
        errors
    }
}

function impulseRateValidate(impuseRate, date, errors) {

    if (!date.startDate) {
        errors.startDate = "Start and End Date is required"
    }

    if (!date.endDate) {
        errors.startDate = "Start and End Date is required"
    }

    if (!impuseRate.impulse_rate) {
        errors.impulse_rate = "Impulse rate is required"
    }

    if (impuseRate?.impulse_rate?.length && impuseRate?.impulse_rate_type === "Wh/imp") {
        if (impuseRate.impulse_rate < 0) {
            errors.impulse_rate = "Impulse rate should be greater than 0"
        }

        let val = 1000 / impuseRate?.impulse_rate

        if (val.toString().includes(".")) {
            errors.impulse_rate = "Please enter a valid impulse rate";
        }
    }

    if (!impuseRate.impulse_rate_type) {
        errors.impulse_rate_type = "Impulse rate type is required"
    }

    return errors

}

function tariffRateValidate(tariffDetail, date, errors) {
    if (!date.startDate) {
        errors.startDate = "Start and End Date is required"
    }

    if (!date.endDate) {
        errors.startDate = "Start and End Date is required"
    }

    if (tariffDetail?.is_flat_rate) {
        if (!tariffDetail?.unit_charge?.toString()) {
            errors.unit_charge = "Cost is required"
        }
    }

    if (!tariffDetail?.is_flat_rate) {
        let tariffPlanError = []
        tariffDetail?.tariff_plan?.length && tariffDetail.tariff_plan.map((val, index) => {
            if (!val?.unit_charge?.toString()) {
                tariffPlanError[index] = "Cost is required"
            }
        })
        if (tariffPlanError.length) {
            errors = { ...errors, tariffPlanError }
        }
    }

    return errors

}