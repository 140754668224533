import React from 'react'
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

export default function CustomDate(props) {
    const orientation = window.matchMedia("(max-width: 1300px)").matches ? 'vertical' : 'horizontal'
    const {
        filters = {},
        setFocusInput = () => { },
        handleDateChange = () => { },
        focusInput = null,
        className = "",
        isBefore = moment().year(),
        isAfter = moment().add(12, 'hours'),
    } = props

    return (
        <div className={`datepicker ${className}`}>
            <DateRangePicker
                // className="custom-dates"
                startDate={filters?.term || null} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={filters?.endDate || null} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => handleDateChange(startDate, endDate)}
                focusedInput={focusInput || null} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(focusedInput) => setFocusInput(focusedInput)} // PropTypes.func.isRequired,
                showDefaultInputIcon
                anchorDirection="left"
                inputIconPosition="after"
                minimumNights={0}
                showClearDates={filters?.term && filters?.endDate ? true : false}
                readOnly={true}
                isOutsideRange={date =>
                    date.isBefore(isBefore) ||
                    date.isAfter(isAfter)
                }
                displayFormat='DD/MM/YYYY'
                hideKeyboardShortcutsPanel
                // withFullScreenPortal={true}
                // withPortal={true}
                orientation={orientation}
            // numberOfMonths={10}

            // defaultValue={null}
            />
        </div>
    )
}