import {
    getAllElectricalRetailerAPI,
    deleteElectricalRetailerAPI,
    createElectricalRetailerAPI,
    updateElectricalRetailerAPI,
    getRetailerListAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_ELECTRICITY_RETAILER_REQUEST = 'GET_ALL_ELECTRICITY_RETAILER_REQUEST';
export const GET_ALL_ELECTRICITY_RETAILER_SUCCESS = 'GET_ALL_ELECTRICITY_RETAILER_SUCCESS';
export const GET_ALL_ELECTRICITY_RETAILER_FAILED = 'GET_ALL_ELECTRICITY_RETAILER_FAILED';

export const getAllElectricalRetailer = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_ELECTRICITY_RETAILER_REQUEST
        })
        getAllElectricalRetailerAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_ELECTRICITY_RETAILER_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_ELECTRICITY_RETAILER_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const DELETE_ELECTRICITY_RETAILER_REQUEST = 'DELETE_ELECTRICITY_RETAILER_REQUEST';
export const DELETE_ELECTRICITY_RETAILER_SUCCESS = 'DELETE_ELECTRICITY_RETAILER_SUCCESS';
export const DELETE_ELECTRICITY_RETAILER_FAILED = 'DELETE_ELECTRICITY_RETAILER_FAILED';

export const deleteElectricalRetailer = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_ELECTRICITY_RETAILER_REQUEST
        })
        deleteElectricalRetailerAPI(id).then(res => {
            dispatch({
                type: DELETE_ELECTRICITY_RETAILER_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_ELECTRICITY_RETAILER_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const CREATE_ELECTRICITY_RETAILER_REQUEST = 'CREATE_ELECTRICITY_RETAILER_REQUEST';
export const CREATE_ELECTRICITY_RETAILER_SUCCESS = 'CREATE_ELECTRICITY_RETAILER_SUCCESS';
export const CREATE_ELECTRICITY_RETAILER_FAILED = 'CREATE_ELECTRICITY_RETAILER_FAILED';

export const createElectricalRetailer = (name, favourite) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: CREATE_ELECTRICITY_RETAILER_REQUEST
        })
        createElectricalRetailerAPI(name, favourite).then(res => {
            dispatch({
                type: CREATE_ELECTRICITY_RETAILER_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: CREATE_ELECTRICITY_RETAILER_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const UPDATE_ELECTRICITY_RETAILER_REQUEST = 'UPDATE_ELECTRICITY_RETAILER_REQUEST';
export const UPDATE_ELECTRICITY_RETAILER_SUCCESS = 'UPDATE_ELECTRICITY_RETAILER_SUCCESS';
export const UPDATE_ELECTRICITY_RETAILER_FAILED = 'UPDATE_ELECTRICITY_RETAILER_FAILED';

export const updateElectricalRetailer = (id, name, favourite) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_ELECTRICITY_RETAILER_REQUEST
        })
        updateElectricalRetailerAPI(id, name, favourite).then(res => {
            dispatch({
                type: UPDATE_ELECTRICITY_RETAILER_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_ELECTRICITY_RETAILER_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_RETAILER_LIST_REQUEST = 'GET_RETAILER_LIST_REQUEST';
export const GET_RETAILER_LIST_SUCCESS = 'GET_RETAILER_LIST_SUCCESS';
export const GET_RETAILER_LIST_FAILED = 'GET_RETAILER_LIST_FAILED';

export const getRetailerList = () => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_RETAILER_LIST_REQUEST
        })
        getRetailerListAPI().then(res => {
            dispatch({
                type: GET_RETAILER_LIST_SUCCESS,
                payload: res && res?.electricity_retailers || []
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_RETAILER_LIST_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const CLEAR_ALL_RETAILER_DATA = 'CLEAR_ALL_RETAILER_DATA';

export const clearStoreForRetailer = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_RETAILER_DATA
        })
    }
}