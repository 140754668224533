import {
    getDataForceAgencyAgentAPI,
    getDataForceInstallerAgentAPI,
    // updateElectricianInstallerAgentAPI,
    getDataForceAgentDevicesForTableAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_DATAFORCE_AGENCY_AGENT_REQUEST = 'GET_DATAFORCE_AGENCY_AGENT_REQUEST';
export const GET_DATAFORCE_AGENCY_AGENT_SUCCESS = 'GET_DATAFORCE_AGENCY_AGENT_SUCCESS';
export const GET_DATAFORCE_AGENCY_AGENT_FAILED = 'GET_DATAFORCE_AGENCY_AGENT_FAILED';

export const getDataForceAgencyAgent = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DATAFORCE_AGENCY_AGENT_REQUEST
        })
        getDataForceAgencyAgentAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_DATAFORCE_AGENCY_AGENT_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DATAFORCE_AGENCY_AGENT_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_DATAFORCE_INSTALLER_AGENTS_REQUEST = 'GET_DATAFORCE_INSTALLER_AGENTS_REQUEST';
export const GET_DATAFORCE_INSTALLER_AGENTS_SUCCESS = 'GET_DATAFORCE_INSTALLER_AGENTS_SUCCESS';
export const GET_DATAFORCE_INSTALLER_AGENTS_FAILED = 'GET_DATAFORCE_INSTALLER_AGENTS_FAILED';

export const getDataForceInstallerAgent = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DATAFORCE_INSTALLER_AGENTS_REQUEST
        })
        getDataForceInstallerAgentAPI(id).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_DATAFORCE_INSTALLER_AGENTS_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DATAFORCE_INSTALLER_AGENTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_REQUEST = "GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_REQUEST";
export const GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_SUCCESS = "GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_SUCCESS";
export const GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_FAILED = "GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_FAILED";

export const getDataForceAgentDevicesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_REQUEST
        })
        getDataForceAgentDevicesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DATAFORCE_AGENT_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const CLEAR_DATAFORCE_AGENCY_AGENTS = 'CLEAR_DATAFORCE_AGENCY_AGENTS';

export const clearDataForceAgencyAgent = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DATAFORCE_AGENCY_AGENTS
        })
    }
}