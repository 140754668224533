import {
    getAllElectriciansAPI,
    getElectricianAPI,
    getElectricianAgentForTableAPI,
    getElecAgentDevicesForTableAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_DATA_FORCE_ELECTRICIANS_REQUEST = 'GET_ALL_DATA_FORCE_ELECTRICIANS_REQUEST';
export const GET_ALL_DATA_FORCE_ELECTRICIANS_SUCCESS = 'GET_ALL_DATA_FORCE_ELECTRICIANS_SUCCESS';
export const GET_ALL_DATA_FORCE_ELECTRICIANS_FAILED = 'GET_ALL_DATA_FORCE_ELECTRICIANS_FAILED';

export const getAllElectricians = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_DATA_FORCE_ELECTRICIANS_REQUEST
        })
        getAllElectriciansAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ALL_DATA_FORCE_ELECTRICIANS_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_DATA_FORCE_ELECTRICIANS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_DATA_FORCE_ELECTRICIAN_BY_ID_REQUEST = 'GET_DATA_FORCE_ELECTRICIAN_BY_ID_REQUEST';
export const GET_DATA_FORCE_ELECTRICIAN_BY_ID_SUCESS = 'GET_DATA_FORCE_ELECTRICIAN_BY_ID_SUCESS';
export const GET_DATA_FORCE_ELECTRICIAN_BY_ID_FAILED = 'GET_DATA_FORCE_ELECTRICIAN_BY_ID_FAILED';

export const getElectrician = id => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_DATA_FORCE_ELECTRICIAN_BY_ID_REQUEST
        })
        getElectricianAPI(id).then(res => {
            dispatch({
                type: GET_DATA_FORCE_ELECTRICIAN_BY_ID_SUCESS,
                payload: res && res.info,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DATA_FORCE_ELECTRICIAN_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })



export const GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_REQUEST = "GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_REQUEST";
export const GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_SUCCESS = "GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_SUCCESS";
export const GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_FAILED = "GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_FAILED";

export const getElectricianAgentsForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_REQUEST
        })
        getElectricianAgentForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST = "GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST";
export const GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS = "GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS";
export const GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED = "GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED";

export const getElecAgentDevicesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST
        })
        getElecAgentDevicesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const CLEAR_DATAFORCE_AGENCY_ELECTRICIAN_DATA = 'CLEAR_DATAFORCE_AGENCY_ELECTRICIAN_DATA';

export const clearStoreForDataForceAgencyElectrician = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DATAFORCE_AGENCY_ELECTRICIAN_DATA
        })
    }
}