import React from 'react'
import { DateRangePicker } from 'rsuite';
const { afterToday } = DateRangePicker

function DateRangePickerRsuit(props) {
    const { value = [], onChangeDate = () => { } } = props
    return (
        <>
            <DateRangePicker
                value={value}
                onChange={(item) => onChangeDate(item)}
                placeholder="Select Date Range"
                character={' - '}
                format={'yyyy-MM-dd'}
                disabledDate={afterToday()}
                preventOverflow
            // placement='bottomEnd'
            />
        </>
    )
}

export default DateRangePickerRsuit