import Validator from 'is_js'
import { isEmpty } from "lodash";
import { passwordValidationRegex } from '../../utils';


export function addElectrician(data) {

    const { id = '' } = data

    let errors = {};
    const emailRegex = /^.+@.+\..+$/
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/

    if (!data.email) {
        errors.email = "Email is required";
    }

    if (data.email && !emailRegex.test(data.email)) {
        errors.email = "Email address is invalid"
    }

    if (!data?.postcode) {
        errors.postcode = "Postal code is required"
    }

    if (data?.postcode?.length && data?.postcode?.length < 3) {
        errors.postcode = "Valid Postal code is required"
    }

    if (!data?.first_name) {
        errors.first_name = "First Name is required"
    }

    if (Validator.empty(data.last_name)) {
        errors.last_name = "Last Name is required"
    }

    if (Validator.empty(data.company_name)) {
        errors.company_name = "Company Name is required"
    }

    if (Validator.empty(data.phone_no) || data.phone_no === undefined) {
        errors.phone_no = "Contact Number is required";
    }

    if (data.phone_no && (data.phone_no.length < 8)) {
        errors.phone_no = "Phone Number must be 8 to 13 characters";
    }

    if (data.phone_no && (data.phone_no.length > 13)) {
        errors.phone_no = "Phone Number must be 8 to 13 characters";
    }

    if (!data.address) {
        errors.address = "Address is required";
    }

    if (!data.abn_number) {
        errors.abn_number = "ABN Number is required";
    }

    if (id && !data?.aggregator?.value) {
        errors.aggregator = "Aggregator is required";
    }

    // if (!id) {
    //     if (!data.password) {
    //         errors.passwordText = "Password is required";
    //     }
    //     if (data.password) {
    //         let passwordRegError = passwordValidationRegex(data.password)
    //         if (!isEmpty(passwordRegError)) {
    //             errors.password = passwordRegError
    //         }
    //     }
    // }

    if (id && data?.password) {
        let passwordRegError = passwordValidationRegex(data.password)
        if (!isEmpty(passwordRegError)) {
            errors.password = passwordRegError
        }
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}