import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import * as commonActions from '../../containers/common/actions'
import * as actions from '../../containers/Auth/actions'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    Button,
    CircularProgress
} from '@material-ui/core';
import { logOut } from '../../utils';


function TokenExpiredModal(props) {
    const dispatch = useDispatch();
    const { confirmModal } = useSelector(state => state.common)
    const { title = '', dialogtext = '', confirmAction = () => { } } = confirmModal || {}


    const handleClose = () => {
        logOut("access-token")
        logOut("login-user-info")
        // dispatch(commonActions.closeTokenExpiredModal())
        props.history.push('/login')
    }

    const confirmedAction = () => {
        dispatch(actions.refreshToken()).then(res => {
            dispatch(commonActions.closeTokenExpiredModal())
        })
    }

    const { open, isLoading = false } = props;

    return (
        <Dialog
            open={open}
            maxWidth="xs"
            fullWidth={true}
            className="cus-dialog py-3"
        >
            <DialogTitle>
                session expired!
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please renew your session to continue.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <div className="dialog-btns">
                    <Button onClick={handleClose} className="button-btn btn-custom-black mr-3" disabled={isLoading}>
                        Exit
                    </Button>
                    <Button onClick={confirmedAction} className="button-btn btn-custom-primary" disabled={isLoading}>
                        {isLoading ? <CircularProgress size={18} color='white'/> : 'Renew'}
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    )
}

export default TokenExpiredModal;