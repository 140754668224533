import React from 'react'

export default function CustomSearchBar(props) {
    const { value = '', onChange = () => { }, name = '', placeholder = "", onChangeKeyDown = () => { } } = props
    return (
        <div className="custom-search-field">
            <input
                type="text"
                placeholder={placeholder}
                value={value || ''}
                onChange={onChange}
                name={name}
                onKeyDown={onChangeKeyDown}
            />
        </div>
    )
}