import { apiGet, apiPost, apiPut } from '../../../utils';
import moment from 'moment'

export function getAllAgencyDevicesAPI(filters) {
    let arr = []

    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (key === "installation_date") {
            if (value[0] == null) continue
            arr.push({
                key,
                value: moment(filters.installation_date[0]).format("DD-MM-YYYY"),
                end_value: moment(filters.installation_date[1]).format("DD-MM-YYYY"),
                type: 'single'
            })
        }
        else if (["device_status", "status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value, type: "single" })
        }
    }
    return apiGet(`/api/agency/electrician/devices?filter=${JSON.stringify(arr)}`)
}

export function downloadCsvAPI(ids, csvtype, allDevice) {

    let obj = {}
    obj = { ...obj, csvtype: csvtype, all: allDevice?.allSelected === true ? true : false, device_uid: allDevice?.allSelected === true ? [] : ids, "group": "electrician" }
    return apiPost('/api/agency/devices/download-csv', obj)
}
