import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allOTA: {},
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {
        case actionsTypes.GET_ALL_OTA_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_OTA_HISTORY_SUCCESS:
            return { ...state, isLoading: false, allOTA: action.payload || {} }

        case actionsTypes.GET_ALL_OTA_HISTORY_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_OTA_HISTORY_DATA:
            return { ...state, ...initialState }

        default:
            return state

    }
}