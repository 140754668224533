
import * as actionsTypes from './actions'
let initialState = {
    isLoading: false,
    devices: [],
    allOTAGroup: {},
    singleOTAGroup: {}
}


export default function GroupReducer(state = { ...initialState }, action) {

    switch (action.type) {
        case actionsTypes.GET_ALL_DEVICE_REQUEST:
            return { ...state, isLoading: false }

        case actionsTypes.GET_ALL_DEVICE_SUCCESS:
            return { ...state, isLoading: false, devices: action.payload || [] }

        case actionsTypes.GET_ALL_DEVICE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_ALL_OTA_GROUP_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_OTA_GROUP_SUCCESS:
            return { ...state, isLoading: false, allOTAGroup: action.payload || {} }

        case actionsTypes.GET_ALL_OTA_GROUP_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_GROUP_DETAILS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_GROUP_DETAILS_SUCCESS:
            return { ...state, isLoading: false, singleOTAGroup: action.payload || {} }

        case actionsTypes.GET_GROUP_DETAILS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_GROUP_INFORMATION_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_GROUP_INFORMATION_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_GROUP_INFORMATION_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_DEVICE_FROM_GROUP_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_DEVICE_FROM_GROUP_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_DEVICE_FROM_GROUP_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_SINGLE_OTA_GROUP_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_SINGLE_OTA_GROUP_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_SINGLE_OTA_GROUP_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_DEVICE_IN_GROUP_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ADD_DEVICE_IN_GROUP_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_DEVICE_IN_GROUP_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_GROUP_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_GROUP_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_GROUP_FAILED:
            return { ...state, isLoading: false }
            

        case actionsTypes.CLEAR_ALL_GROUP_STORE: {
            return { ...state, ...initialState }
        }

        default:
            return state

    }

}