import React from 'react'
import { Grid, Button, CircularProgress, Typography } from "@material-ui/core"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from "react-router-dom"

function CommonAddComponent(props) {
    const history = useHistory();
    const {
        icon,
        title,
        saveBtnLable = 'Save',
        cancelBtnLable = 'Cancel',
        onSaveBtn,
        onCancelBtn,
        loader = false,
        footer = true,
        editButton = true,
        editRoute = '',
        editButtonName = "Edit",
        goBack = '',
        deleteButton = false,
        onDelete = () => { },
        isShowBtn = true
    } = props;

    return (
        <>
            <Grid container className="location-container" >
                <Grid item xs={12} >
                    <Grid container className="location-head mb-4 con-space-between">
                        <div className='d-flex align-items-center'>
                            <div className="back-btn">
                                <div className="back-icon back-btn" onClick={goBack ? () => history.push(goBack) : () => history.goBack()}>
                                    <ArrowBackIosIcon />
                                </div>
                            </div>
                            <h2>{title}</h2>
                        </div>
                        {isShowBtn ? <div>
                            {deleteButton ? <Button className=" btn-custom-border edit-btn button-btn" onClick={onDelete}>Delete</Button> : null}
                            {editButton ? <Button className="ml-3 btn-custom-border edit-btn button-btn" onClick={() => history.push(editRoute)}>{editButtonName}</Button> : null}
                        </div> : null}
                    </Grid>
                </Grid>
                <Grid container className=" common-add-component location-head">
                    {/* <Grid item xs={12} className="heading header">
                        <h2 className="d-flex align-center"> 
                            {title}
                        </h2>
                    </Grid> */}
                    <Grid item xs={12} >
                        {props.children}
                    </Grid>

                    {footer && <Grid item xs={12} className="footer">
                        <div className="buttons-div">
                            <Button className=" btn-custom-border edit-btn button-btn" onClick={onCancelBtn} disabled={loader}>{cancelBtnLable}</Button>
                            <Button className=" button-btn btn-custom-primary" onClick={onSaveBtn} disabled={loader}>{loader ? <CircularProgress className="add-loader" size={22} color='white' /> : saveBtnLable}</Button>
                        </div>
                    </Grid>}
                </Grid>
            </Grid>
        </>
    )
}

export default CommonAddComponent
