import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allFirmware: {},
    totalFirmwares: 0,
    createLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_FIRMWARE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_FIRMWARE_SUCCESS:
            return { ...state, isLoading: false, allFirmware: action.payload || {}, totalFirmwares: action.total }

        case actionsTypes.GET_ALL_FIRMWARE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CREATE_FIRMWARE_REQUEST:
            return { ...state, createLoading: true }

        case actionsTypes.CREATE_FIRMWARE_SUCCESS:
            return { ...state, createLoading: false }

        case actionsTypes.CREATE_FIRMWARE_FAILED:
            return { ...state, createLoading: false }

        case actionsTypes.UPDATE_FIRMWARE_REQUEST:
            return { ...state, createLoading: true }

        case actionsTypes.UPDATE_FIRMWARE_SUCCESS:
            return { ...state, createLoading: false, }

        case actionsTypes.UPDATE_FIRMWARE_FAILED:
            return { ...state, createLoading: false }

        case actionsTypes.CLEAR_STORE_FOR_FIRMWARE_DATA:
            return { ...state, ...initialState }

        default:
            return state

    }
}