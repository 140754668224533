import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allBulbDevices: {},
    singleBulbDevice: {},
    deviceHistory: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_BULB_DEVICES_REQUEST :
            return { ...state, isLoading: true }

        case actionsTypes.GET_BULB_DEVICES_SUCCESS:
            return { ...state, isLoading: false, allBulbDevices: action.payload || {} }

        case actionsTypes.GET_BULB_DEVICES_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_SINGLE_BULB_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_SINGLE_BULB_SUCCESS:
            return { ...state, isLoading: false, singleBulbDevice: action.payload || {} }

        case actionsTypes.GET_SINGLE_BULB_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_BULB_DEVICE_HISTORY_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_BULB_DEVICE_HISTORY_SUCCESS:
            return { ...state, isLoading: false, deviceHistory: action.payload || [] }

        case actionsTypes.GET_BULB_DEVICE_HISTORY_FAILED:
            return { ...state, isLoading: false }

        default:
            return state
    }
}