import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allOTA: {},
    firmwareList: [],
    isSaveFirmwareLoading: false
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {
        case actionsTypes.GET_ALL_OTA_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_OTA_SUCCESS:
            const { info = {}, firmwares = [] } = action.payload
            return { ...state, isLoading: false, allOTA: info, firmwareList: firmwares }

        case actionsTypes.GET_ALL_OTA_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_DEVICE_FIRMWARE_REQUEST:
            return { ...state, isSaveFirmwareLoading: true }

        case actionsTypes.UPDATE_DEVICE_FIRMWARE_SUCCESSS:
            return { ...state, isSaveFirmwareLoading: false }

        case actionsTypes.UPDATE_DEVICE_FIRMWARE_FAILED:
            return { ...state, isSaveFirmwareLoading: false }

        case actionsTypes.CLEAR_STORE_FOR_OTA:
            return { ...state, ...initialState }
            
        default:
            return state

    }
}