import Validator from "is_js";
import { isEmpty } from "lodash";


export function addRetailerItem(name) {
    let errors = {};

    if (Validator.empty(name)) {
        errors.retailerName = "Retailer Name is required"
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}
