import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allDevices: {},
    singleDevice: {},
    deviceHistory: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_DEVICES_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_DEVICES_SUCCESS:
            return { ...state, isLoading: false, allDevices: action.payload || {} }

        case actionsTypes.GET_ALL_DEVICES_FAILED:
            return { ...state, isLoading: false }
        default:
            return state
    }
}