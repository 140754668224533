import { isEmpty } from "lodash";

export function adminProfileValidate(data) {

    let errors = {};

    if( (data.password || data.confirmPassword) && data.password !==data.confirmPassword){
        errors.confirmPassword = 'Password and Confirm password should match'
    }

    if (!data.first_name || data.first_name===''){
        errors.first_name = "First Name is required"
    }
    
    if(!data.last_name ||  data.last_name===''){
        errors.last_name = "Last Name is required"
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}