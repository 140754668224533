import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allElectricians: {},
    electricianAgentsForTable: {},
    electricianAgentsIsLoading: false,
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_DATA_FORCE_ELECTRICIANS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_DATA_FORCE_ELECTRICIANS_SUCCESS:
            return { ...state, isLoading: false, allElectricians: action.payload || {} }

        case actionsTypes.GET_ALL_DATA_FORCE_ELECTRICIANS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_BY_ID_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_BY_ID_SUCESS:
            return { ...state, isLoading: false, electrician: action.payload || {} }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_BY_ID_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_REQUEST:
            return { ...state, electricianAgentsIsLoading: true }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_SUCCESS:
            return { ...state, electricianAgentsIsLoading: false, electricianAgentsForTable: action.payload || {} }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_AGENT_FOR_TABLE_FAILED:
            return { ...state, electricianAgentsIsLoading: false }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST:
            return { ...state, eleAgentDevicesIsLoading: true }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS:
            return { ...state, eleAgentDevicesIsLoading: false, eleAgentDevicesForTable: action.payload || {} }

        case actionsTypes.GET_DATA_FORCE_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED:
            return { ...state, eleAgentDevicesIsLoading: false }

        case actionsTypes.CLEAR_DATAFORCE_AGENCY_ELECTRICIAN_DATA:
            return { ...state, ...initialState }

        default:
            return state

    }
}