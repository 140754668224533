import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allElectricalRetailers: {},
    retailerList: []
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_ELECTRICITY_RETAILER_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_ELECTRICITY_RETAILER_SUCCESS:
            return { ...state, isLoading: false, allElectricalRetailers: action.payload || {} }

        case actionsTypes.GET_ALL_ELECTRICITY_RETAILER_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CREATE_ELECTRICITY_RETAILER_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.CREATE_ELECTRICITY_RETAILER_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.CREATE_ELECTRICITY_RETAILER_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_ELECTRICITY_RETAILER_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_ELECTRICITY_RETAILER_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_ELECTRICITY_RETAILER_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_ELECTRICITY_RETAILER_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_ELECTRICITY_RETAILER_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_ELECTRICITY_RETAILER_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_RETAILER_LIST_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_RETAILER_LIST_SUCCESS:
            return { ...state, isLoading: false, retailerList: action.payload || [] }

        case actionsTypes.GET_RETAILER_LIST_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_RETAILER_DATA:
            return { ...state, ...initialState }

        default:
            return state
    }
}