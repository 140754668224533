import { apiPost, apiGet, apiPut } from '../../../utils'


export function getElectricianUserProfileAPI() {
    return apiGet('/api/electrician/getprofile')
}

export function editElectricianUserProfileAPI(data) {
    let { aggregator = {}, password = '', state = {}, ...rest } = data

    if ((state?.value) && (state?.value !== null) || (state?.value !== undefined)) {
        rest = { ...rest, state: state.value }
    }
    
    if (password) {
        rest = { ...rest, password }
    }
    return apiPut('/api/electrician/editprofile', rest)
}