import Validator from 'is_js'
import { isEmpty } from "lodash";
import { passwordValidationRegex } from '../../utils';

export function editInstallationAgent(data, id) {

    let errors = {};
    const emailRegex = /^.+@.+\..+$/
    const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8,}$/

    if (!data.firstName || Validator.empty(data.firstName)) {
        errors.firstName = "First Name is required";
    }

    if (data.firstName && data.firstName.length > 200) {
        errors.firstName = "First Name should be less than 200 character";
    }

    if (!data.lastName || Validator.empty(data.lastName)) {
        errors.lastName = "Last Name is required";
    }

    if (data.lastName && data.lastName.length > 200) {
        errors.lastName = "Last Name should be less than 200 character";
    }

    if (Validator.empty(data.email)) {
        errors.email = "Email is required";
    }

    if (!Validator.empty(data.email) && !emailRegex.test(data.email)) {
        errors.email = "Entered Email address is invalid"
    }

    if (Validator.empty(data.mobile) || data.mobile === undefined) {
        errors.mobile = "Phone Number is required";
    }

    if (data.mobile && (data.mobile.length < 8)) {
        errors.mobile = "Phone Number must be 8 to 13 characters";
    }

    if (data.mobile && (data.mobile.length > 13)) {
        errors.mobile = "Phone Number must be 8 to 13 characters";
    }

    if (!id) {
        if (!data.password) {
            errors.passwordText = "Password is required";
        }
        if (data.password) {
            let passwordRegError = passwordValidationRegex(data.password)
            if (!isEmpty(passwordRegError)) {
                errors.password = passwordRegError
            }
        }
    }

    if (id && data?.password) {
        let passwordRegError = passwordValidationRegex(data.password)
        if (!isEmpty(passwordRegError)) {
            errors.password = passwordRegError
        }
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}