import { saveObject } from '../../utils';
import * as actionsTypes from './actions';

const initalState = {
    snackbar: {
        open: false,
        messageType: 'success',
        message: '',
        timer: 4000,
    },
    confirmModal: {
        dialogtext: '',
        isOpen: false,
        confirmAction: null,
        title: '',
        description: ''
    },
    editModal: {
        isOpen: false,
        title: '',
    },
    tokenExpiredModal: {
        isTokenExpired: '',
    },
    globalModal: {
        isOpenDialog: false,
        data: {},
        modalName: null,
        isLoading: false,
    },
    isDarkMode: '',
    selectedSideBarProductLoading: false,
    selectedSideBarProduct: ""
}


export default function (state = { ...initalState }, action) {

    switch (action.type) {
        case actionsTypes.OPEN_SNACKBAR_STATE: {
            const { messageType = 'success', message = '', timer = 4000 } = action.payload || {}
            return { ...state, snackbar: { ...state.snackbar, open: true, messageType, message, timer } }
        }

        case actionsTypes.CLOSE_SNACKBAR_STATE: {
            return { ...state, snackbar: { ...state.snackbar, open: false } }
        }

        case actionsTypes.OPEN_GLOBAL_DIALOG: {
            return { ...state, globalModal: { ...state.globalModal, isOpenDialog: true, data: action.data || {}, modalName: action.modalName || null } }
        }

        case actionsTypes.CLOSE_GLOBAL_DIALOG: {
            return { ...state, globalModal: { ...state.globalModal, isOpenDialog: false, data: action.data || {}, modalName: action.modalName || null } }
        }

        case actionsTypes.OPEN_DELETE_MODAL: {
            const { confirmAction, title, dialogtext, description = '' } = action.payload || {}
            return { ...state, confirmModal: { ...state.confirmModal, isOpen: true, confirmAction, title, dialogtext, description } }
        }

        case actionsTypes.CLOSE_DELETE_MODAL: {
            return { ...state, confirmModal: { ...state.confirmModal, isOpen: false, confirmAction: null, title: '', dialogtext: '', description: '' } }
        }

        case actionsTypes.OPEN_EDIT_MODAL: {
            const { title } = action.payload || {}
            return { ...state, editModal: { ...state.editModal, isOpen: true, title } }
        }

        case actionsTypes.CLOSE_EDIT_MODAL: {
            return { ...state, editModal: { ...state.editModal, isOpen: false, title: '', } }
        }

        case actionsTypes.OPEN_TOKEN_EXPIRED_MODAL: {
            return { ...state, tokenExpiredModal: { ...state.tokenExpiredModal, isTokenExpired: true } }
        }

        case actionsTypes.CLOSE_TOKEN_EXPIRED_MODAL: {
            return { ...state, tokenExpiredModal: { ...state.tokenExpiredModal, isTokenExpired: false } }
        }

        case actionsTypes.CHANGE_SIDEBAR_PRODUCT_LOADING: {
            return { ...state, selectedSideBarProductLoading: true }
        }

        case actionsTypes.CHANGE_SIDEBAR_PRODUCT: {
            return { ...state, selectedSideBarProduct: action.payload, selectedSideBarProductLoading: false }
        }

        case actionsTypes.DARK_THEME: {
            window.localStorage.setItem("isDarkMode", action.payload);
            return { ...state, isDarkMode: action.payload }
        }

        default:
            return state
    }
}

