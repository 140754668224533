import { apiDelete, apiGet, apiPost, apiPut } from '../../../utils';
import moment from 'moment';

export function getAllElectriciansAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue

        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/agency/electrician/get/all?filter=${JSON.stringify(arr)}`)

}

export function getElectricianAPI(id) {
    return apiGet(`/api/agency/electrician/get/${id}`)
}

export function getElectricianAgentForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/agency/electrician/get-agents?electrician_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`)
}

export function getElecAgentDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/agency/electrician/get-devices?electrician_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}`)
}


