import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {
    getAllCoustomerAPI,
    getCustomerDetailAPI,
    deleteCustomerAPI,
    getPropertyDetailsAPI,
    updatePropertyDetailAPI,
    updateCustomerDetailAPI,
    electricityRateHistoryAPI,
    deletePropertyAPI,
    getCusLoginPayloadForTableAPI,
    getCusAdminPropertiesForTableAPI,
    getCusSharedPropertiesForTableAPI,
    getCusPropertyMembersForTableAPI,
    getCusPropertyDevicesForTableAPI,
    getCustomerEmailHistoryAPI,
    deleteMemberAPI,
    downloadCSVAllCustomersAPI,
    getPropertyTimelineHistoryAPI,
    downloadCSVTrialCustomersAPI,
    getTrialHistoryAPI,
    downloadCSVTrialforSingleCustomerAPI,
    downloadAutoSyncFeedbackFormAPI,
    downloadAutoSyncFormCsvAPI,
    getCusPropertyLivelinkDevicesForTableAPI,
    addPropertyMemberAPI
} from './apis'


export const GET_ALL_CUSTOMER_REQUEST = 'GET_ALL_CUSTOMER_REQUEST';
export const GET_ALL_CUSTOMER_SUCCESS = 'GET_ALL_CUSTOMER_SUCCESS';
export const GET_ALL_CUSTOMER_FAILED = 'GET_ALL_CUSTOMER_FAILED';

export const getAllCustomers = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_CUSTOMER_REQUEST
        })
        getAllCoustomerAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_CUSTOMER_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_CUSTOMER_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })

    })


export const GET_CUSTOMER_DETAIL_REQUEST = 'GET_CUSTOMER_DETAIL_REQUEST';
export const GET_CUSTOMER_DETAIL_SUCCESS = 'GET_CUSTOMER_DETAIL_SUCCESS';
export const GET_CUSTOMER_DETAIL_FAILED = 'GET_CUSTOMER_DETAIL_FAILED';

export const getCustomerDetail = (id) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_CUSTOMER_DETAIL_REQUEST
        })
        getCustomerDetailAPI(id).then(res => {
            dispatch({
                type: GET_CUSTOMER_DETAIL_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CUSTOMER_DETAIL_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })

    })

export const DELETE_CUSTOMER_REQUEST = 'DELETE_CUSTOMER_REQUEST'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED'

export const deleteCustomer = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_CUSTOMER_REQUEST
        })
        deleteCustomerAPI(id).then(res => {
            dispatch({
                type: DELETE_CUSTOMER_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_CUSTOMER_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const DELETE_PROPERTY_REQUEST = 'DELETE_PROPERTY_REQUEST';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAILED = 'DELETE_PROPERTY_FAILED';

export const deleteProperty = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_PROPERTY_REQUEST
        })
        deletePropertyAPI(id).then(res => {
            dispatch({
                type: DELETE_PROPERTY_SUCCESS
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_PROPERTY_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}


export const GET_PROPERTY_DETAIL_REQUEST = 'GET_PROPERTY_DETAIL_REQUEST'
export const GET_PROPERTY_DETAIL_SUCCESS = 'const GET_PROPERTY_DETAIL_SUCCESS'
export const GET_PROPERTY_DETAIL__FAILED = 'const GET_PROPERTY_DETAIL__FAILED'

export const getPropertyDetail = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_PROPERTY_DETAIL_REQUEST
        })
        getPropertyDetailsAPI(id).then(res => {
            const { info } = res
            dispatch({
                type: GET_PROPERTY_DETAIL_SUCCESS,
                payload: info
            })
            return resolve(info)
        }).catch(err => {
            dispatch({
                type: GET_PROPERTY_DETAIL__FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })

    })

export const UPDATE_PROPERTY_DETAIL_REQUEST = 'UPDATE_PROPERTY_DETAIL_REQUEST'
export const UPDATE_PROPERTY_DETAIL_SUCCESS = 'UPDATE_PROPERTY_DETAIL_SUCCESS'
export const UPDATE_PROPERTY_DETAIL_FAILED = 'UPDATE_PROPERTY_DETAIL_FAILED'

export const updatePropertyDetail = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_PROPERTY_DETAIL_REQUEST
        })
        updatePropertyDetailAPI(data).then(res => {
            const { info } = res
            dispatch({
                type: UPDATE_PROPERTY_DETAIL_SUCCESS,
                payload: info
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(info)
        }).catch(err => {
            dispatch({
                type: UPDATE_PROPERTY_DETAIL_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })

    })


export const CLEAR_ALL_CUSTOMER_DATA = 'CLEAR_ALL_CUSTOMER_DATA';

export const clearStoreForCutomer = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_CUSTOMER_DATA
        })
    }
}


export const CLEAR_ALL_CUSTOMER_DATA_PROPERTY_DEVICE = 'CLEAR_ALL_CUSTOMER_DATA_PROPERTY_DEVICE';

export const clearStorePropertyDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_CUSTOMER_DATA_PROPERTY_DEVICE
        })
    }
}

export const UPDATE_CUSTOMER_DETAIL_REQUEST = 'UPDATE_CUSTOMER_DETAIL_REQUEST'
export const UPDATE_CUSTOMER_DETAIL_SUCCESS = 'UPDATE_CUSTOMER_DETAIL_SUCCESS'
export const UPDATE_CUSTOMER_DETAIL_FAILED = 'UPDATE_CUSTOMER_DETAIL_FAILED'

export const updateCustomerDetail = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_CUSTOMER_DETAIL_REQUEST
        })
        updateCustomerDetailAPI(data).then(res => {
            const { info } = res
            dispatch({
                type: UPDATE_CUSTOMER_DETAIL_SUCCESS,
                payload: info
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(info)
        }).catch(err => {
            dispatch({
                type: UPDATE_CUSTOMER_DETAIL_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })

    })

export const GET_ELECTRICITY_RATE_HISTORY_REQUEST = "GET_ELECTRICITY_RATE_HISTORY_REQUEST";
export const GET_ELECTRICITY_RATE_HISTORY_SUCCESS = "GET_ELECTRICITY_RATE_HISTORY_SUCCESS";
export const GET_ELECTRICITY_RATE_HISTORY_FAILED = "GET_ELECTRICITY_RATE_HISTORY_FAILED";

export const electricityRateHistory = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_ELECTRICITY_RATE_HISTORY_REQUEST
        })
        electricityRateHistoryAPI(id).then(res => {
            const { info = [] } = res
            dispatch({
                type: GET_ELECTRICITY_RATE_HISTORY_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ELECTRICITY_RATE_HISTORY_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_REQUEST = "GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_REQUEST";
export const GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_SUCCESS = "GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_SUCCESS";
export const GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_FAILED = "GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_FAILED";

export const getCusLoginPayloadForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_REQUEST
        })
        getCusLoginPayloadForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CUSTOMER_LOGIN_PAYLOAD_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_REQUEST = "GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_REQUEST";
export const GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_SUCCESS = "GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_SUCCESS";
export const GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_FAILED = "GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_FAILED";

export const getCusAdminPropertiesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_REQUEST
        })
        getCusAdminPropertiesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CUSTOMER_ADMIN_PROPERTIES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_CUSTOMER_SHARED_PROPERTIES_FOR_TABLE_REQUEST = "GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_REQUEST";
export const GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_SUCCESS = "GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_SUCCESS";
export const GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_FAILED = "GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_FAILED";

export const getCusSharedPropertiesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_CUSTOMER_SHARED_PROPERTIES_FOR_TABLE_REQUEST
        })
        getCusSharedPropertiesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CUSTOMER_SHARED_ADMIN_PROPERTIES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_REQUEST = "GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_REQUEST";
export const GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_SUCCESS = "GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_SUCCESS";
export const GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_FAILED = "GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_FAILED";

export const getCusPropertyMembersForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_REQUEST
        })
        getCusPropertyMembersForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CUSTOMER_PROPERTY_MEMBERS_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const ADD_CUSTOMER_PROPERTY_MEMBERS__REQUEST = "ADD_CUSTOMER_PROPERTY_MEMBERS__REQUEST";
export const ADD_CUSTOMER_PROPERTY_MEMBERS__SUCCESS = "ADD_CUSTOMER_PROPERTY_MEMBERS__SUCCESS";
export const ADD_CUSTOMER_PROPERTY_MEMBERS__FAILED = "GET_CUSTOMER_PROPERTY_MEMBERS__FAILED";

export const addCusPropertyMember = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: ADD_CUSTOMER_PROPERTY_MEMBERS__REQUEST
        })
        addPropertyMemberAPI(data).then(res => {
            dispatch({
                type: ADD_CUSTOMER_PROPERTY_MEMBERS__SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res?.message, messageType: 'success' }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: ADD_CUSTOMER_PROPERTY_MEMBERS__FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}




export const GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_REQUEST = "GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_REQUEST";
export const GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_SUCCESS = "GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_SUCCESS";
export const GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_FAILED = "GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_FAILED";

export const getCusPropertyDevicesForTable = (filters, type) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_REQUEST
        })
        getCusPropertyDevicesForTableAPI(filters, type).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CUSTOMER_PROPERTY_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const GET_CUSTOMER_EMAIL_HISTORY_REQUEST = "GET_CUSTOMER_EMAIL_HISTORY_REQUEST";
export const GET_CUSTOMER_EMAIL_HISTORY_SUCCESS = "GET_CUSTOMER_EMAIL_HISTORY_SUCCESS";
export const GET_CUSTOMER_EMAIL_HISTORY_FAILED = "GET_CUSTOMER_EMAIL_HISTORY_FAILED";

export const getCustomerEmailHistory = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_CUSTOMER_EMAIL_HISTORY_REQUEST
        })
        getCustomerEmailHistoryAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_CUSTOMER_EMAIL_HISTORY_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_CUSTOMER_EMAIL_HISTORY_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const DELETE_MEMBER_REQUEST = "DELETE_MEMBER_REQUEST";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAILED = "DELETE_MEMBER_FAILED";

export const deleteMember = (obj) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_MEMBER_REQUEST
        })
        deleteMemberAPI(obj).then(res => {
            dispatch({
                type: DELETE_MEMBER_SUCCESS,
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_MEMBER_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}


export const GET_PROPERTY_TIMELINE_HISTORY_REQUEST = "GET_PROPERTY_TIMELINE_HISTORY_REQUEST";
export const GET_PROPERTY_TIMELINE_HISTORY_SUCCESS = "GET_PROPERTY_TIMELINE_HISTORY_SUCCESS";
export const GET_PROPERTY_TIMELINE_HISTORY_FAILED = "GET_PROPERTY_TIMELINE_HISTORY_FAILED";

export const getPropertyTimelineHistory = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_PROPERTY_TIMELINE_HISTORY_REQUEST
        })
        getPropertyTimelineHistoryAPI(id).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_PROPERTY_TIMELINE_HISTORY_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_PROPERTY_TIMELINE_HISTORY_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}


export const GET_TRIAL_HISTORY_REQUEST = "GET_TRIAL_HISTORY_REQUEST";
export const GET_TRIAL_HISTORY_SUCCESS = "GET_TRIAL_HISTORY_SUCCESS";
export const GET_TRIAL_HISTORY_FAILED = "GET_TRIAL_HISTORY_FAILED";

export const getTrialHistory = (id, syncType) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_TRIAL_HISTORY_REQUEST
        })
        getTrialHistoryAPI(id, syncType).then(res => {
            // const { info = [] } = res
            dispatch({
                type: GET_TRIAL_HISTORY_SUCCESS,
                payload: res || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_TRIAL_HISTORY_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const downloadCSVAllCustomers = () => dispatch => {
    return new Promise((resolve, reject) => {
        downloadCSVAllCustomersAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const downloadCSVTrialCustomers = () => dispatch => {
    return new Promise((resolve, reject) => {
        downloadCSVTrialCustomersAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}


export const downloadCSVTrialforSingleCustomer = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        downloadCSVTrialforSingleCustomerAPI(id).then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const downloadAutoSyncFeedbackForm = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        downloadAutoSyncFeedbackFormAPI(id).then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}


export const downloadAutoSyncFormCsv = () => dispatch => {
    return new Promise((resolve, reject) => {
        downloadAutoSyncFormCsvAPI().then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}






