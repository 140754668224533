import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'
import {
    getAllDevicesAPI
} from './apis'

export const GET_ALL_DEVICES_REQUEST = 'GET_ALL_DEVICES_REQUEST';
export const GET_ALL_DEVICES_SUCCESS = 'GET_ALL_DEVICES_SUCCESS';
export const GET_ALL_DEVICES_FAILED = 'GET_ALL_DEVICES_FAILED';

export const getAllDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_DEVICES_REQUEST
        })
        getAllDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_DEVICES_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_DEVICES_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
