
import * as actionsTypes from './actions'
let initialState = {
    isLoading: false,
    allRule: {}
}


export default function ruleReducer(state = { ...initialState }, action) {

    switch (action.type) {

        case actionsTypes.GET_ALL_RULE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_RULE_SUCCESS:
            return { ...state, isLoading: false, allRule: action.payload || {} }

        case actionsTypes.GET_ALL_RULE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_NEW_RULE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.ADD_NEW_RULE_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.ADD_NEW_RULE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_RULE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.UPDATE_RULE_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_RULE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_RULE_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.DELETE_RULE_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.DELETE_RULE_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_RULE_STORE:
            return { ...state, ...initialState }

        default:
            return state

    }

}