import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as commonActions from "../../../containers/common/actions"
import AddOTAGroup from '../../../containers/Admin/OTAGroups/AddOTAGroup'
import Dialog from './Dialog';



export default function CustomGlobalDialog() {
    const dispatch = useDispatch();

    const { globalModal } = useSelector(state => state.common);

    const closeModal = () => {
        dispatch(commonActions.closeGlobalDialog({}))
    }


    switch (globalModal?.modalName) {
        case "ADD_OTA_GROUP":
            return <RenderModalWithComponent component={AddOTAGroup} customFunction={closeModal} maxWidth="sm" globalModal={globalModal} />

        default:
            return null
    }
}

const RenderModalWithComponent = ({ component: Component, maxWidth = "sm", customFunction, className = '', ...rest }) => {
    return (
        <>
            <Dialog fullWidth maxWidth={maxWidth} customFunction={customFunction} className={`${className}cus-dialog py-3 `}>
                <Component {...rest} closeModal={customFunction} />
            </Dialog>
        </>
    )
}

