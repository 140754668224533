import React from 'react'
import { Accordion, AccordionSummary, FormControlLabel, AccordionDetails, Checkbox } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function CustomAccordion(props) {

    const { isOpen = false, label = '', handleChange = () => { }, children = null } = props

    return (
            <Accordion expanded={isOpen}  >
                <AccordionSummary
                    // expandIcon={<ExpandMoreIcon />}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id="additional-actions1-header"
                    className=''
                >
                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={<Checkbox />}
                        label={label}
                        onChange={handleChange}
                        checked={isOpen}
                    />
                </AccordionSummary>
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            </Accordion>
    )
}