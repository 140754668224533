import { getAllAgencyDevicesAPI, getAgencyDeviceDetailAPI, downloadCsvAPI, updateDeviceDetailAPI } from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_DATAFORCE_AGENCY_DEVIECS_REQUEST = 'GET_ALL_DATAFORCE_AGENCY_DEVIECS_REQUEST';
export const GET_ALL_DATAFORCE_AGENCY_DEVIECS_SUCCESS = 'GET_ALL_DATAFORCE_AGENCY_DEVIECS_SUCCESS';
export const GET_ALL_DATAFORCE_AGENCY_DEVIECS_FAILED = 'GET_ALL_DATAFORCE_AGENCY_DEVIECS_FAILED';

export const getAllDataForceAgencyDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_DATAFORCE_AGENCY_DEVIECS_REQUEST
        })
        getAllAgencyDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_DATAFORCE_AGENCY_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_DATAFORCE_AGENCY_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const downloadCsv = (ids, csvtype, allDevice) => dispatch =>
    new Promise((resolve, reject) => {
        downloadCsvAPI(ids, csvtype, allDevice).then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })

export const CLEAR_ALL_DATAFORCE_AGENCY_DEVICE_DATA = 'CLEAR_ALL_DATAFORCE_AGENCY_DEVICE_DATA';

export const clearStoreForAgencyDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_DATAFORCE_AGENCY_DEVICE_DATA
        })
    }
}
