import React, { useState } from "react"
import { TextField, IconButton, InputAdornment, Input as PatternInput } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import { COUNTRY_CODE } from '@constants';
import MaskedInput from 'react-text-mask';


export default function Input(props) {
    const { onChange, value, error = '', type = 'text', placeholder = '', id = '', className = '', label, disabled = false, pattern = '', maxLength = null, ...rest } = props

    const [show, setShow] = useState(false);

    function toggle() {
        setShow(!show)
    }
    if (type === 'phone') {
        return (
            <React.Fragment>
                <TextField
                    id={id}
                    type="text"
                    value={value}
                    onChange={onChange}
                    label={label}
                    variant="outlined"
                    className={className}
                    placeholder={placeholder}
                    disabled={disabled}
                    // InputProps={{
                    //     startAdornment: <InputAdornment position="start">{COUNTRY_CODE || "+91"}</InputAdornment>
                    // }}
                    {...rest}
                />
                {error && <p className="error">{error}</p>}
            </React.Fragment>
        )
    }

    if (type === 'password') {
        return (
            <React.Fragment>
                <TextField
                    id={id}
                    type={show ? 'text' : type}
                    value={value || ''}
                    onChange={onChange}
                    label={label}
                    variant="outlined"
                    className={className}
                    placeholder={placeholder}
                    // autoComplete='new-password'
                    InputProps={{
                        autoComplete: 'none',
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={toggle}
                            >
                                {show ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    {...rest}
                />
                {error && <p className="error">{error}</p>}
            </React.Fragment>
        )
    }

    if (type === 'pattern') {
        return (
            <React.Fragment>
                <PatternInput
                    value={value}
                    onChange={onChange}
                    id="formatted-text-mask-input"
                    inputComponent={() => <TextMaskCustom pattern={pattern} />}
                    className={className}
                    {...rest}
                />
                {error && <p className="error">{error}</p>}
            </React.Fragment>
        )

    }

    return (
        <React.Fragment>
            <TextField
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                label={label}
                variant="outlined"
                className={className}
                placeholder={placeholder}
                disabled={disabled}
                inputProps={{ maxLength }}
                {...rest}
            />
            {error && <p className="error">{error}</p>}
        </React.Fragment>
    )
}

function TextMaskCustom(props) {
    const { pattern, inputRef, ...other } = props;
    return (
        <MaskedInput
            {...other}
            // ref={(ref) => {
            //     inputRef(ref ? ref.inputElement : null);
            // }}
            mask={pattern}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}
