import {
    getAllElectriciansAPI,
    getElectricianAPI,
    getElectricianAgentForTableAPI,
    deleteElectricianAPI,
    updateElectricianAPI,
    getAllAggregatorsAPI,
    createElectricianAPI,
    getElecAgentDevicesForTableAPI
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_ELECTRICIANS_REQUEST = 'GET_ALL_ELECTRICIANS_REQUEST';
export const GET_ALL_ELECTRICIANS_SUCCESS = 'GET_ALL_ELECTRICIANS_SUCCESS';
export const GET_ALL_ELECTRICIANS_FAILED = 'GET_ALL_ELECTRICIANS_FAILED';

export const getAllElectricians = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_ELECTRICIANS_REQUEST
        })
        getAllElectriciansAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ALL_ELECTRICIANS_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_ELECTRICIANS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })



export const GET_ELECTRICIAN_BY_ID_REQUEST = 'GET_ELECTRICIAN_BY_ID_REQUEST';
export const GET_ELECTRICIAN_BY_ID_SUCESS = 'GET_ELECTRICIAN_BY_ID_SUCESS';
export const GET_ELECTRICIAN_BY_ID_FAILED = 'GET_ELECTRICIAN_BY_ID_FAILED';

export const getElectrician = id => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ELECTRICIAN_BY_ID_REQUEST
        })
        getElectricianAPI(id).then(res => {
            dispatch({
                type: GET_ELECTRICIAN_BY_ID_SUCESS,
                payload: res && res.info,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ELECTRICIAN_BY_ID_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_ELECTRICIAN_AGENT_FOR_TABLE_REQUEST = "GET_ELECTRICIAN_AGENT_FOR_TABLE_REQUEST";
export const GET_ELECTRICIAN_AGENT_FOR_TABLE_SUCCESS = "GET_ELECTRICIAN_AGENT_FOR_TABLE_SUCCESS";
export const GET_ELECTRICIAN_AGENT_FOR_TABLE_FAILED = "GET_ELECTRICIAN_AGENT_FOR_TABLE_FAILED";

export const getElectricianAgentsForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_ELECTRICIAN_AGENT_FOR_TABLE_REQUEST
        })
        getElectricianAgentForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ELECTRICIAN_AGENT_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ELECTRICIAN_AGENT_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const DELETE_ELECTRICIAN_REQUEST = 'DELETE_ELECTRICIAN_REQUEST';
export const DELETE_ELECTRICIAN_SUCCESS = 'DELETE_ELECTRICIAN_SUCCESS';
export const DELETE_ELECTRICIAN_FAILED = 'DELETE_ELECTRICIAN_FAILED';

export const deleteElectrician = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: DELETE_ELECTRICIAN_REQUEST
        })
        deleteElectricianAPI(id).then(res => {
            dispatch({
                type: DELETE_ELECTRICIAN_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: DELETE_ELECTRICIAN_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })



export const UPDATE_ELECTRICIAN_REQUEST = 'UPDATE_ELECTRICIAN_REQUEST';
export const UPDATE_ELECTRICIAN_SUCCESS = 'UPDATE_ELECTRICIAN_SUCCESS';
export const UPDATE_ELECTRICIAN_FAILED = 'UPDATE_ELECTRICIAN_FAILED';

export const updateElectrician = (data, id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_ELECTRICIAN_REQUEST
        })
        updateElectricianAPI(data, id).then(res => {
            dispatch({
                type: UPDATE_ELECTRICIAN_SUCCESS,
                payload: res
            })
            dispatch(commonActions.openSnackbarsState({ message: res && res.message }))
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: UPDATE_ELECTRICIAN_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_ALL_AGGREGATORS_LIST_REQUEST = 'GET_ALL_AGGREGATORS_LIST_REQUEST';
export const GET_ALL_AGGREGATORS_LIST_SUCCESS = 'GET_ALL_AGGREGATORS_LIST_SUCCESS';
export const GET_ALL_AGGREGATORS_LIST_FAILED = 'GET_ALL_AGGREGATORS_LIST_FAILED';

export function getAllAggregators() {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "GET_ALL_AGGREGATORS_LIST_REQUEST"
            })
            getAllAggregatorsAPI().then(res => {
                const { info = {} } = res || {}
                dispatch({
                    type: 'GET_ALL_AGGREGATORS_LIST_SUCCESS',
                    payload: info || {}
                })
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'GET_ALL_AGGREGATORS_LIST_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}

export const CREATE_ELECTRICIAN_REQUEST = 'CREATE_ELECTRICIAN_REQUEST';
export const CREATE_ELECTRICIAN_SUCCESS = 'CREATE_ELECTRICIAN_SUCCESS';
export const CREATE_ELECTRICIAN_FAILED = 'CREATE_ELECTRICIAN_FAILED';

export function createElectrician(data) {
    return function (dispatch) {
        return new Promise((resolve, reject) => {
            dispatch({
                type: "CREATE_ELECTRICIAN_REQUEST"
            })
            createElectricianAPI(data).then(res => {
                const { info = {} } = res || {}
                dispatch({
                    type: 'CREATE_ELECTRICIAN_SUCCESS',
                    payload: info || {}
                })
                resolve(res)
            }).catch(err => {
                dispatch({
                    type: 'CREATE_ELECTRICIAN_FAILED'
                })
                dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
                reject(err)
            })
        })
    }
}


export const GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST = "GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST";
export const GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS = "GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS";
export const GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED = "GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED";

export const getElecAgentDevicesForTable = (filters) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_REQUEST
        })
        getElecAgentDevicesForTableAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ADMIN_ELECTRICIAN_AGENT_DEVICES_FOR_TABLE_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })
}

export const CLEAR_ADMIN_ELECTRICIAN_DATA = 'CLEAR_ADMIN_ELECTRICIAN_DATA';

export const clearStoreForElectrician = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ADMIN_ELECTRICIAN_DATA
        })
    }
}