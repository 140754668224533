import { errorMessage } from "../../../../utils";
import *  as commonActions from "../../../common/actions"

import {
    getUserBaseDeviceAPI,
    getUserWithWeeklyReportsAPI,
    getAutoSyncUserDataAPI,
    getUnsubscribeUserDataAPI,
    getUserWithUnsubscribeWeeklyReportsAPI,
    getUserSyncDataWithFiltersAPI,
    getAverageBatteryLifeAPI,
    getWeeklyReportsAPI,
    getLocationPreferenceAPI,
    getVersionListAPI,
} from './api'
import { TotalUserAppVersionAPI } from "./api";

export const GET_USER_APP_VERSION_REQUEST = 'GET_USER_APP_VERSION_REQUEST';
export const GET_USER_APP_VERSION_SUCCESS = 'GET_USER_APP_VERSION_SUCCESS';
export const GET_USER_APP_VERSION_FAILED = 'GET_USER_APP_VERSION_FAILED';

export const getUserCountTotalVersion = (filters = [], date,chartYear) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_USER_APP_VERSION_REQUEST
        })
        TotalUserAppVersionAPI(filters, date,chartYear).then(res => {
            dispatch({
                type: GET_USER_APP_VERSION_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_USER_APP_VERSION_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })



export const GET_USER_BASE_DEVICE_REQUEST = 'GET_USER_BASE_DEVICE_REQUEST';
export const GET_USER_BASE_DEVICE_SUCCESS = 'GET_USER_BASE_DEVICE_SUCCESS';
export const GET_USER_BASE_DEVICE_FAILED = 'GET_USER_BASE_DEVICE_FAILED';

export const getUserBaseDeviceData = (filters = [], date,chartYear="") => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_USER_BASE_DEVICE_REQUEST
        })
        getUserBaseDeviceAPI(filters, date,chartYear).then(res => {
            dispatch({
                type: GET_USER_BASE_DEVICE_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_USER_BASE_DEVICE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const USER_WITH_TOTAL_WEEKLY_REPORTS_REQUEST = 'USER_WITH_TOTAL_WEEKLY_REPORTS_REQUEST';
export const USER_WITH_TOTAL_WEEKLY_REPORTS_SUCCESS = 'USER_WITH_TOTAL_WEEKLY_REPORTS_SUCCESS';
export const USER_WITH_TOTAL_WEEKLY_REPORTS_FAILED = 'USER_WITH_TOTAL_WEEKLY_REPORTS_FAILED';

export const getWeeklyReportBaseUserData = (filters = [], date,chartYear) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: USER_WITH_TOTAL_WEEKLY_REPORTS_REQUEST
        })
        getUserWithWeeklyReportsAPI(filters, date,chartYear).then(res => {
            dispatch({
                type: USER_WITH_TOTAL_WEEKLY_REPORTS_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: USER_WITH_TOTAL_WEEKLY_REPORTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_AUTO_SYNC_ON_USER_DATA_REQUEST = "GET_AUTO_SYNC_ON_USER_DATA_REQUEST";
export const GET_AUTO_SYNC_ON_USER_DATA_SUCCESS = "GET_AUTO_SYNC_ON_USER_DATA_SUCCESS";
export const GET_AUTO_SYNC_ON_USER_DATA_FAILED = "GET_AUTO_SYNC_ON_USER_DATA_FAILED";

export const getAutoSyncUserData = (filters = [], date,chartYear) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_AUTO_SYNC_ON_USER_DATA_REQUEST
        })
        getAutoSyncUserDataAPI(filters, date,chartYear).then(res => {
            dispatch({
                type: GET_AUTO_SYNC_ON_USER_DATA_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AUTO_SYNC_ON_USER_DATA_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_UNSUBSCRIBE_USER_DATA_REQUEST = "GET_UNSUBSCRIBE_USER_DATA_REQUEST";
export const GET_UNSUBSCRIBE_USER_DATA_SUCCESS = "GET_UNSUBSCRIBE_USER_DATA_SUCCESS";
export const GET_UNSUBSCRIBE_USER_DATA_FAILED = "GET_UNSUBSCRIBE_USER_DATA_FAILED";

export const getUnsubscribeUserData = (filters = [], date,chartYear) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_UNSUBSCRIBE_USER_DATA_REQUEST
        })
        getUnsubscribeUserDataAPI(filters, date,chartYear).then(res => {
            dispatch({
                type: GET_UNSUBSCRIBE_USER_DATA_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_UNSUBSCRIBE_USER_DATA_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_REQUEST = "GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_REQUEST";
export const GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_SUCCESS = "GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_SUCCESS";
export const GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_FAILED = "GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_FAILED";

export const getUnsubscribeUserWeeklyReportsData = (filters = [], date) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_REQUEST
        })
        getUserWithUnsubscribeWeeklyReportsAPI(filters, date).then(res => {
            dispatch({
                type: GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_USER_UNSCUBSCRIBE_WEEKLY_REPORTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_USER_SYNC_DATA_WITH_FILTERS_REQUEST = "GET_USER_SYNC_DATA_WITH_FILTERS_REQUEST";
export const GET_USER_SYNC_DATA_WITH_FILTERS_SUCCESS = "GET_USER_SYNC_DATA_WITH_FILTERS_SUCCESS";
export const GET_USER_SYNC_DATA_WITH_FILTERS_FAILED = "GET_USER_SYNC_DATA_WITH_FILTERS_FAILED";

export const getUserSyncDataWithFilters = (deviceType, date,chartYear) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_USER_SYNC_DATA_WITH_FILTERS_REQUEST
        })
        getUserSyncDataWithFiltersAPI(deviceType, date,chartYear).then(res => {
            dispatch({
                type: GET_USER_SYNC_DATA_WITH_FILTERS_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_USER_SYNC_DATA_WITH_FILTERS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_AVERAGE_BATTERY_LIFE_REQUEST = "GET_AVERAGE_BATTERY_LIFE_REQUEST";
export const GET_AVERAGE_BATTERY_LIFE_SUCCESS = "GET_AVERAGE_BATTERY_LIFE_SUCCESS";
export const GET_AVERAGE_BATTERY_LIFE_FAILED = "GET_AVERAGE_BATTERY_LIFE_FAILED";

export const getAverageBatteryLifeData = (filters = [], year,chartYear) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_AVERAGE_BATTERY_LIFE_REQUEST
        })
        getAverageBatteryLifeAPI(filters, year,chartYear).then(res => {
            dispatch({
                type: GET_AVERAGE_BATTERY_LIFE_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_AVERAGE_BATTERY_LIFE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_WEEKLY_REPORTS_REQUEST = "GET_WEEKLY_REPORTS_REQUEST";
export const GET_WEEKLY_REPORTS_SUCCESS = "GET_WEEKLY_REPORTS_SUCCESS";
export const GET_WEEKLY_REPORTS_FAILED = "GET_WEEKLY_REPORTS_FAILED";

export const getWeeklyReportsData = (filters = [], date) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_WEEKLY_REPORTS_REQUEST
        })
        getWeeklyReportsAPI(filters, date).then(res => {
            dispatch({
                type: GET_WEEKLY_REPORTS_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_WEEKLY_REPORTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_LOCATION_PREFERENCE_REQUEST = "GET_LOCATION_PREFERENCE_REQUEST";
export const GET_LOCATION_PREFERENCE_SUCCESS = "GET_LOCATION_PREFERENCE_SUCCESS";
export const GET_LOCATION_PREFERENCE_FAILED = "GET_LOCATION_PREFERENCE_FAILED";

export const getLocationPreferenceData = (deviceType = "", date,chartYear) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_LOCATION_PREFERENCE_REQUEST
        })
        getLocationPreferenceAPI(deviceType, date,chartYear).then(res => {
            dispatch({
                type: GET_LOCATION_PREFERENCE_SUCCESS,
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_LOCATION_PREFERENCE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const GET_APP_VERSION_LIST_REQUEST = "GET_APP_VERSION_LIST_REQUEST";
export const GET_APP_VERSION_LIST_SUCCESS = "GET_APP_VERSION_LIST_SUCCESS";
export const GET_APP_VERSION_LIST_FAILED = "GET_APP_VERSION_LIST_FAILED";

export const getAppVersionListData = () => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_APP_VERSION_LIST_REQUEST
        })
        getVersionListAPI().then(res => {
            dispatch({
                type: GET_APP_VERSION_LIST_SUCCESS,
                payload: res.info.versions
            })
            return resolve()
        }).catch(err => {
            dispatch({
                type: GET_APP_VERSION_LIST_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const CLEAR_ALL_DEVICE_DATA = 'CLEAR_ALL_DEVICE_DATA';

export const clearStoreForDashboard = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_DEVICE_DATA
        })
    }
}
