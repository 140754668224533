import { getAllElectricianDevicesAPI, getElectricianDeviceDetailAPI, downloadCsvAPI, updateDeviceDetailAPI } from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_ELECTRICIAN_DEVIECS_REQUEST = 'GET_ALL_ELECTRICIAN_DEVIECS_REQUEST';
export const GET_ALL_ELECTRICIAN_DEVIECS_SUCCESS = 'GET_ALL_ELECTRICIAN_DEVIECS_SUCCESS';
export const GET_ALL_ELECTRICIAN_DEVIECS_FAILED = 'GET_ALL_ELECTRICIAN_DEVIECS_FAILED';

export const getAllElectricianDevices = (filters) => dispatch =>

    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_ELECTRICIAN_DEVIECS_REQUEST
        })
        getAllElectricianDevicesAPI(filters).then(res => {
            dispatch({
                type: GET_ALL_ELECTRICIAN_DEVIECS_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_ELECTRICIAN_DEVIECS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })


export const downloadCsv = (ids, csvtype, allDevice) => dispatch =>
    new Promise((resolve, reject) => {
        downloadCsvAPI(ids, csvtype, allDevice).then(res => {
            return resolve(res)
        }).catch(err => {
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })

export const GET_ELECTRICIAN_DEVICE_DETAIL_REQUEST = 'GET_ELECTRICIAN_DEVICE_DETAIL_REQUEST';
export const GET_ELECTRICIAN_DEVICE_DETAIL_SUCCESS = 'GET_ELECTRICIAN_DEVICE_DETAIL_SUCCESS';
export const GET_ELECTRICIAN_DEVICE_DETAIL_FAILED = 'GET_ELECTRICIAN_DEVICE_DETAIL_FAILED';

export const getElectricianDeviceDetail = (id) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ELECTRICIAN_DEVICE_DETAIL_REQUEST
        })
        getElectricianDeviceDetailAPI(id).then(res => {
            dispatch({
                type: GET_ELECTRICIAN_DEVICE_DETAIL_SUCCESS,
                payload: res && res?.info || {}
                // payload: {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ELECTRICIAN_DEVICE_DETAIL_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })

export const UPDATE_DEVICE_DETAIL_REQUEST = 'UPDATE_DEVICE_DETAIL_REQUEST'
export const UPDATE_DEVICE_DETAIL_SUCCESS = 'UPDATE_DEVICE_DETAIL_SUCCESS'
export const UPDATE_DEVICE_DETAIL_FAILED = 'UPDATE_DEVICE_DETAIL_FAILED'

export const updateDeviceDetail = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: UPDATE_DEVICE_DETAIL_REQUEST
        })
        updateDeviceDetailAPI(data).then(res => {
            const { info } = res
            dispatch({
                type: UPDATE_DEVICE_DETAIL_SUCCESS,
                payload: info
            })
            return resolve(info)
        }).catch(err => {
            dispatch({
                type: UPDATE_DEVICE_DETAIL_FAILED,
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })

    })

    
export const CLEAR_ALL_ELECTRICIAN_DEVICE_DATA = 'CLEAR_ALL_ELECTRICIAN_DEVICE_DATA';

export const clearStoreForElectricianDevice = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_ELECTRICIAN_DEVICE_DATA
        })
    }
}