import { getElectricianUserProfileAPI, editElectricianUserProfileAPI } from './apis.js';
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ELECTRICIAN_USER_PROFILE_REQUEST = 'GET_ELECTRICIAN_USER_PROFILE_REQUEST';
export const GET_ELECTRICIAN_USER_PROFILE_SUCCESS = 'GET_ELECTRICIAN_USER_PROFILE_SUCCESS';
export const GET_ELECTRICIAN_USER_PROFILE_FAILED = 'GET_ELECTRICIAN_USER_PROFILE_FAILED';

export const getElectricianUserProfile = () => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ELECTRICIAN_USER_PROFILE_REQUEST
        })
        getElectricianUserProfileAPI().then(res => {
            dispatch({
                type: GET_ELECTRICIAN_USER_PROFILE_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ELECTRICIAN_USER_PROFILE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })

export const EDIT_ELECTRICIAN_USER_PROFILE_REQUEST = 'EDIT_ELECTRICIAN_USER_PROFILE_REQUEST';
export const EDIT_ELECTRICIAN_USER_PROFILE_SUCCESS = 'EDIT_ELECTRICIAN_USER_PROFILE_SUCCESS';
export const EDIT_ELECTRICIAN_USER_PROFILE_FAILED = 'EDIT_ELECTRICIAN_USER_PROFILE_FAILED';

export const editElectricianUserProfile = (data) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: EDIT_ELECTRICIAN_USER_PROFILE_REQUEST
        })
        editElectricianUserProfileAPI(data).then(res => {
            dispatch({
                type: EDIT_ELECTRICIAN_USER_PROFILE_SUCCESS,
                payload: res && res.info || {}
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: EDIT_ELECTRICIAN_USER_PROFILE_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            reject(err)
        })
    })

export const CLEAR_ELECTRICIAN_PANEL_PROFILE_STORE_DATA = 'CLEAR_ELECTRICIAN_PANEL_PROFILE_STORE_DATA'

export const clearStoreForElecProfileStore = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ELECTRICIAN_PANEL_PROFILE_STORE_DATA
        })
    }
}