import React, { useState, useEffect, useCallback } from "react";
import { withStyles } from '@material-ui/core/styles';
import EnhancedTableHead from "../../../components/common/EnhancedTableHead";
import CustomTablePagination from "../../../components/common/CustomPagination";
import { withTranslation } from "react-i18next";
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { tablestyle, dateWithTime, trimFilters } from '../../../utils'
import AddIcon from '@material-ui/icons/Add';
import SearchField from "../../../components/common/SearchField";
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import EditIcon from '@material-ui/icons/Edit';
import TableLoader from "../../../components/common/TableLoader";
import * as actions from './actions';
import DeleteIcon from '@material-ui/icons/Delete';
import * as commonActions from '../../common/actions'
import * as OTAAction from '../OTAs/actions'
import {
    Grid,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Switch,
    FormControlLabel,
    Select,
    InputLabel,
    FormControl,
    MenuItem,
} from '@material-ui/core';
import CustomDrawer from "../../../components/common/CustomDrawer";
import CustomAccordion from "../../../components/common/CustomAccordion";
import CustomSearchBar from "../../../components/common/CustomSearchBar";
import qs from 'query-string'
import { useHistory, useLocation } from "react-router-dom";
import SearchFilterButtons from "../../../components/common/SearchFilterButtons";
import CusBackdrop from '../../../components/common/Backdrop'

const manageHead = [
    { id: 'created_at', sortByKey: 'created_at', label: "Added At", className: "show-pointer" },
    { id: 'name', sortByKey: 'name', label: "Name", className: "show-pointer" },
    { id: 'target_version', sortByKey: 'target_version', label: "Target Version", className: "show-pointer" },
    { id: 'upgradable', sortByKey: 'upgradable', label: "Update Flag", className: "show-pointer" },
    { id: "action", sortByKey: 'action', label: "Action", stickyClassName: "more" }
];

const manageHeadSafelink = [
    { id: 'created_at', sortByKey: 'created_at', label: "Added At", className: "show-pointer" },
    { id: 'name', sortByKey: 'name', label: "Name", className: "show-pointer" },
    { id: 'target_version', sortByKey: 'target_version', label: "Target Version", className: "show-pointer" },
    { id: 'upgradable', sortByKey: 'upgradable', label: "Update Flag", className: "show-pointer" },
    // { id: 'force_update', sortByKey: 'force_update', label: "Force Update", className: "show-pointer" },
    { id: "action", sortByKey: 'action', label: "Action", stickyClassName: "more" }
];

const initialAccordion = {
    nameAcc: false,
}

const initialFilter = {
    isOpen: false,
    name: '',
    limit: 25,
    page: 1,
    sortBy: '',
    orderBy: 'desc'
}


function OTAGroups(props) {

    const dispatch = useDispatch();
    const history = useHistory()
    const location = useLocation()
    const [refresh, setRefresh] = useState(false);
    const [accordion, setAccordian] = useState({ ...initialAccordion })
    const [newFilters, setNewFilters] = useState({ ...initialFilter })
    const [deleteGroupId, setdeleteGroupId] = useState('')
    const [randomValue, setRandomvalue] = useState('')
    const [goToPage, setGoToPage] = useState('')
    const [clearFilterLoader, setClearFilterLoader] = useState(false)

    const { firmwareList = [] } = useSelector(state => state.OTA)
    const { allOTAGroup = [], isLoading = false } = useSelector(state => state.OTAGroupReducer)
    const { data: otaGroupList = [], total = 0 } = allOTAGroup
    const { selectedSideBarProduct = '' } = useSelector(state => state.common)

    const delaySearch = useCallback(_.debounce(filters => refreshGroups(filters), 500), []);
    const delayGoToPagination = useCallback(_.debounce(filters => goToPageFun(filters), 1000), []);

    useEffect(() => {
        dispatch(OTAAction.getAllOTA())
        let tempFilter = {}
        if (location.search) {
            let data = { ...qs.parse(location.search, { arrayFormat: 'bracket', }) }
            let {
                nameAcc = false,
                isOpen = false,
                name = '',
                limit = 25,
                page = 1,
                sortBy = '',
                orderBy = 'desc'
            } = data

            tempFilter = {
                isOpen: isOpen === "false" ? false : true,
                name,
                limit: parseInt(limit),
                page: parseInt(page),
                sortBy,
                orderBy,
            }

            let tempAcc = {
                nameAcc: nameAcc === "false" ? false : true,
            }
            setNewFilters(tempFilter)
            setAccordian(tempAcc)
        }
        refreshGroups(tempFilter)
        return () => {
            dispatch(actions.clearStoreForGroup())
        }
    }, [])

    useEffect(() => {
        let tempFilter = { ...accordion, ...trimFilters(newFilters) }
        location.search = qs.stringify({ ...tempFilter })
        history.push({
            pathname: '/admin/OTA-groups',
            search: location.search
        })
    }, [accordion, newFilters])


    const refreshGroups = (filters) => {
        dispatch(actions.getAllOTAGroup(filters))
    }

    const goToPageFun = (value) => {
        refreshGroups(value)
        // setNewFilters({ ...filters, page: parseInt(value) })
    }

    const onClickRefresh = () => {
        setRefresh(true)
        dispatch(actions.getAllOTAGroup(newFilters)).then(() => setRefresh(false)).catch(err => setRefresh(false))
    }

    const onPageChange = (e, page) => {
        refreshGroups({ ...newFilters, page })
        setNewFilters({ ...newFilters, page })
    }

    const onDeleteGroup = (id) => {
        setdeleteGroupId(id)
        setRandomvalue(Math.random())
    }

    useEffect(() => {
        if (deleteGroupId) {
            dispatch(commonActions.openDeleteModal({
                confirmAction: deleteRule,
                title: 'DELETE GROUP',
                dialogtext: 'Are you sure you want to delete this Group?'
            }))
        }
    }, [randomValue])

    const deleteRule = () => {
        dispatch(actions.deleteSinglegroup(deleteGroupId)).then(res => {
            setNewFilters({ ...newFilters, limit: newFilters?.limit, page: 1, sortBy: 'created_at', orderBy: 'desc' })
            refreshGroups({ ...newFilters, limit: newFilters?.limit, page: 1, sortBy: 'created_at', orderBy: 'desc' })
            setdeleteGroupId('')
        })
    }

    const onChangeLimit = (e) => {
        refreshGroups({ ...newFilters, limit: e.target.value, page: 1, sortBy: 'created_at', orderBy: 'desc', })
        setNewFilters({ ...newFilters, limit: e.target.value, page: 1, })
        setGoToPage('')
    }

    const onSortBy = (label) => {
        if (label === "action") return
        let orderBy = ''
        if (newFilters.orderBy === 'desc') {
            orderBy = 'asc'
        } else {
            orderBy = 'desc'
        }
        const filters = { ...newFilters, sortBy: label, orderBy: orderBy }
        setNewFilters(filters)
        refreshGroups(filters)
    }

    const openAddGroupDialog = (item) => {
        dispatch(commonActions.openGlobalDialog({ getAllOTAGroupFn: refreshGroups }, 'ADD_OTA_GROUP'))
    }

    const handleTargetVersion = (e, item) => {
        item = {
            ...item,
            "group_id": item.id,
            "group_name": item.name,
            "current_version": item.current_version,
            "upgradable": item.upgradable,
            target_version: e.target.value
        }
        dispatch(actions.updateGroupInformation(item)).then(res => {
            refreshGroups(newFilters)
        })
    }

    const onChangeUpgradeCheckbox = (e, item) => {
        if (!item.target_version) {
            dispatch(commonActions.openSnackbarsState({ message: "Please select Target Version before sending Firmware Update.", messageType: 'error' }))
            return
        }
        item = { ...item, upgradable: e.target.checked }
        item = {
            ...item,
            "group_id": item.id,
            "group_name": item.name,
            "current_version": item.current_version,
            "target_version": item.target_version,
            upgradable: e.target.checked
        }
        dispatch(actions.updateGroupInformation(item)).then(res => {
            refreshGroups(newFilters)
        })

    }

    const onChangeGoToPage = ({ target: { value = '' } }) => {
        if (!value.match(/^[0-9]*$/) || value === "0") return
        let pageValue = Math.ceil(total / newFilters.limit)
        if (value > pageValue) return
        setGoToPage(value ? parseInt(value) : '')
        setNewFilters({ ...newFilters, page: value ? parseInt(value) : '' })
        delayGoToPagination({ ...newFilters, page: value })
    }

    const openFilterSidebar = () => {
        setNewFilters({ ...newFilters, isOpen: true })
    }

    const onCloseFiltersDrawer = () => {
        setNewFilters({ ...newFilters, isOpen: false })
    }

    const handleChangeAccordion = (panel, filterKey) => (event, isExpanded) => {
        if (!isExpanded) {
            setNewFilters({ ...newFilters, [filterKey]: '', page: newFilters[filterKey]?.length ? 1 : newFilters.page })
        }
        setAccordian({ ...accordion, [panel]: isExpanded })
        // if (newFilters[filterKey]?.length) {
        //     refreshGroups({ ...newFilters, [filterKey]: '', page: 1 })
        // }
    };

    const onChangeFilter = ({ target: { name = '', value = '' } }) => {
        let tempFilter = { ...newFilters }
        tempFilter = { ...tempFilter, [name]: value, page: 1 }
        setNewFilters(tempFilter)
        // delaySearch({ ...tempFilter })
    }

    const applyFilter = () => {
        refreshGroups({ ...trimFilters(newFilters), page: 1 })
    }

    const clearFilter = () => {
        setClearFilterLoader(true)
        let tempFilter = {
            isOpen: newFilters.isOpen,
            serial_number: '',
            firmware_version: '',
            upgradable: [],
            limit: 25,
            page: 1,
            sortBy: '',
            orderBy: 'desc'
        }

        setNewFilters({ ...tempFilter })
        setAccordian({ ...initialAccordion })
        dispatch(actions.getAllOTAGroup(tempFilter)).then(res => {
            setClearFilterLoader(false)
        })
    }

    const onChangeKeyDown = (e) => {
        if (e.key === 'Enter') {
            refreshGroups({ ...trimFilters(newFilters), page: 1 })
        }
    }


    const { t = () => { }, toast, classes } = props;

    return (
        <Grid container className="location-container" >
            <Grid item xs={12} >
                <Grid container className="location-head">
                    <Grid item xs={12} sm={12} lg={6} md={4} className="heading">
                        <h2>Groups</h2>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={6} md={8}>
                        <div className="search-add">
                            <Button className="button-btn btn-custom-primary mr-3" onClick={openAddGroupDialog}><AddIcon />Add Group</Button>
                            <button className="filter-btn-style" onClick={openFilterSidebar}><img src="/images/filter.svg" /></button>
                            <CustomDrawer isOpen={newFilters.isOpen} onClose={onCloseFiltersDrawer} >
                                <ul className="list-style-list">
                                    <CusBackdrop open={isLoading} className="loader-images" />
                                    <li>
                                        <CustomAccordion
                                            isOpen={accordion.nameAcc}
                                            handleChange={handleChangeAccordion('nameAcc', 'name')}
                                            label={"Group Name"} >
                                            <CustomSearchBar
                                                placeholder="Enter Group Name"
                                                value={newFilters.name}
                                                onChange={onChangeFilter}
                                                name={'name'}
                                                onChangeKeyDown={onChangeKeyDown}
                                            />
                                        </CustomAccordion>
                                    </li>
                                    <li>
                                        <SearchFilterButtons
                                            clearFilter={clearFilter}
                                            applyFilter={applyFilter}
                                            isLoading={isLoading}
                                            clearFilterLoader={clearFilterLoader}
                                        />
                                    </li>
                                </ul>
                            </CustomDrawer>
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} className="content-container">
                <div id="no-more-tables" className="card-design table-design cf" >

                    {
                        isLoading ?
                            <>
                                <TableContainer className={classes.container} style={{ overflow: "hidden" }}>
                                    <Table className="table-program" stickyHeader aria-label="sticky table">
                                        <EnhancedTableHead
                                            headCells={selectedSideBarProduct === "safelink" ? manageHeadSafelink : manageHead}
                                        />
                                    </Table>
                                </TableContainer>
                                <TableLoader />
                            </>
                            : null
                    }

                    {
                        !isLoading ? <TableContainer className={classes.container}>
                            <Table className="table-program" stickyHeader aria-label="sticky table">
                                <EnhancedTableHead
                                    headCells={selectedSideBarProduct === "safelink" ? manageHeadSafelink : manageHead}
                                    onSortBy={onSortBy}
                                    filters={newFilters}
                                />
                                <TableBody>
                                    {
                                        otaGroupList?.length ? otaGroupList.map((item, index) => {
                                            return <TableRow key={item?.id} hover role="checkbox" className="cursor_default">
                                                <TableCell className="table-custom-width">{dateWithTime(item.created_at) || "-"}</TableCell>
                                                <TableCell className="table-custom-width">{item.name || "-"}</TableCell>
                                                <TableCell className="table-custom-width table-custom-select">
                                                    <FormControl variant="outlined">
                                                        <InputLabel id="demo-simple-select-outlined-label">Version</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-outlined-label"
                                                            id="demo-simple-select-outlined"
                                                            value={item?.target_version || ""}
                                                            onChange={(e) => handleTargetVersion(e, item)}
                                                            // label="Age"
                                                            className="ota-select"
                                                        >

                                                            {
                                                                firmwareList.map((res, index) => {
                                                                    return <MenuItem key={index} value={res?.version}>{res?.version}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </TableCell>

                                                <TableCell className="table-custom-width">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={!!item.upgradable}
                                                                onChange={(e) => onChangeUpgradeCheckbox(e, item)}
                                                                name="status"
                                                                color="primary"
                                                            />
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell className="table-custom-width more">
                                                    <EditIcon className="show-pointer" onClick={() => history.push(`/admin/OTA-groups/${item.id}${location?.search}`)} />
                                                    <DeleteIcon className="show-pointer" onClick={() => onDeleteGroup(item.id)} />
                                                </TableCell>
                                            </TableRow>
                                        })
                                            :
                                            <TableRow>
                                                <TableCell style={{ border: "none" }} colSpan="6" className="table-no-data">No Data Found</TableCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer> : null
                    }

                    <CustomTablePagination
                        count={total}
                        refresh={refresh}
                        onClickRefresh={onClickRefresh}
                        filters={newFilters}
                        onPageChange={onPageChange}
                        onChangeLimit={onChangeLimit}
                        onChangeGoToPage={onChangeGoToPage}
                        goToPage={goToPage}
                    />
                </div>
            </Grid>
        </Grid >
    );

}

export default withTranslation("translations")(withStyles(tablestyle)(OTAGroups));