import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Popover
} from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import InfoIcon from '@material-ui/icons/Info';
import { useState } from "react";

function EnhancedTableHead(props) {
  const {
    headCells,
    checkbox = false,
    onSelectAllClick = () => { },
    numSelected = 0,
    isHeadChecked = false,
    matchedAtleastOne = false,
    onSortBy = () => { },
    filters = {},

  } = props;
  const [anchorEl, setAnchorEl] = useState("")
  const [selectedInfo, setSelectedInfo] = useState(null)

  const { sortBy = '', orderBy = '' } = filters || {}

  const onMouseOverHandler = (e, id) => {
    setAnchorEl(e.target)
    setSelectedInfo(id)
  }
  const onMouseLeaveHandler = () => {
    setAnchorEl("")
    setSelectedInfo("")
  }
  return (
    <TableHead>
      <TableRow>
        {
          checkbox ? <TableCell padding="checkbox">
            <Checkbox
              indeterminate={(numSelected > 0 && matchedAtleastOne && !isHeadChecked) || false}
              checked={(numSelected && isHeadChecked) || false}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          </TableCell> : null
        }

        {
          headCells.map((headCell) => {
            const { isMore = false } = headCell;
            return <TableCell key={headCell.id} className={headCell.stickyClassName}>
              <div className="d-flex align-items-center"   >
                <span className={headCell.className} onClick={() => onSortBy(headCell.sortByKey)}>
                  {headCell.label}
                  {sortBy === headCell.sortByKey ? orderBy === "asc" ? <ArrowUpwardIcon className="icon" /> : <ArrowDownwardIcon className="icon" /> : null}
                </span>
                {
                  isMore && <InfoIcon onMouseOver={(e) => onMouseOverHandler(e, headCell.id)} className={`icon ${headCell.className}`} />
                }
                {
                  selectedInfo && selectedInfo === headCell.id && isMore && <Popover
                    id={headCell.id}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={e => setAnchorEl(null)}
                    transitionDuration={500}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                  >
                    {
                      selectedInfo === 'status' ? <div className="cus-pointer tableMoreInfo">
                        <div className="mt-2">
                          <span className="text-white" style={{ background: '#d89d1d', padding: '5px', borderRadius: '5px' }}>Pending</span>
                          <p onMouseLeave={onMouseLeaveHandler} className='mt-2'>
                            Customer account created by installer but user hasn't yet logged and created their account/used Installer Passcode.
                          </p>
                        </div>
                        <div className="mt-2">
                          <span className="text-white" style={{ background: 'green', padding: '5px', borderRadius: '5px' }}>Completed</span>
                          <p onMouseLeave={onMouseLeaveHandler} className='mt-2'>
                            Installer finished installation and customer created account. IHD is added to property.
                          </p>
                        </div>

                        <div className="mt-2">
                          <span className="text-white" style={{ background: 'red', padding: '5px', borderRadius: '5px' }}>Deleted</span>
                          <p onMouseLeave={onMouseLeaveHandler} className='mt-2'>
                            Customer or Super Admin deleted the account.
                          </p>
                        </div>
                      </div> : <div className="cus-pointer tableMoreInfo">
                        <div className="mt-2">
                          <span className="text-white" style={{ background: 'green', padding: '5px', borderRadius: '5px' }}>Active</span>
                          <p onMouseLeave={onMouseLeaveHandler} className='mt-2'>
                            IHD is installed.
                          </p>
                        </div>
                        <div className="mt-2">
                          <span className="text-white" style={{ background: 'red', padding: '5px', borderRadius: '5px' }}>Inactive</span>
                          <p onMouseLeave={onMouseLeaveHandler} className='mt-2'>
                            Account still exists but IHD has been removed.
                          </p>
                        </div>
                        <div className="mt-2">
                          <span className="text-white" style={{ background: '#d89d1d', padding: '5px', borderRadius: '5px' }}>Replaced</span>
                          <p onMouseLeave={onMouseLeaveHandler} className='mt-2'>
                            IHD has been replaced using the replace device feature.
                          </p>
                        </div>

                      </div>
                    }
                    {/* <p className='cus-pointer tableMoreInfo' onMouseLeave={onMouseLeaveHandler}>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an 
                    </p> */}
                  </Popover>
                }

              </div>
            </TableCell>
          })
        }
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;