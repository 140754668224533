import * as actionsTypes from './actions';

const initialState = {
    isLoading: false,
    allDevices: {},
    device: {}
}

export default function (state = { ...initialState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ALL_AGENCY_DEVIECS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ALL_AGENCY_DEVIECS_SUCCESS:
            return { ...state, isLoading: false, allDevices: action.payload || {} }

        case actionsTypes.GET_ALL_AGENCY_DEVIECS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.GET_AGENCY_DEVICE_DETAIL_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_AGENCY_DEVICE_DETAIL_SUCCESS:
            return { ...state, isLoading: false, device: action.payload || {} }

        case actionsTypes.GET_AGENCY_DEVICE_DETAIL_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_ALL_DEVICE_DATA:
            return { ...state, ...initialState }

        case actionsTypes.UPDATE_AGENCY_DEVICE_DETAIL_REQUEST:
            return { ...state, isLoading: true, }

        case actionsTypes.UPDATE_AGENCY_DEVICE_DETAIL_SUCCESS:
            return { ...state, isLoading: false }

        case actionsTypes.UPDATE_AGENCY_DEVICE_DETAIL_FAILED:
            return { ...state, isLoading: false }

        default:
            return state 
    }
}