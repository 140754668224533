import { apiGet } from '../../../utils';
import moment from 'moment';

export function getAllWeeklyReportsAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {

        if (key === "isOpen") continue

        if (typeof value !== "number" && !value?.length) continue

        arr.push({ key, value, type: "single" })
    }
    return apiGet(`/api/customer/suspension-list?filter=${JSON.stringify(arr)}`)

}