import { apiGet } from '../../../../utils'


export function TotalUserAppVersionAPI(filters = [], date = "",chartYear="") {
    let arr = []
    filters?.forEach((filter) => {
        for (const [key, value] of Object.entries(filter)) {
            arr.push({ key, value: encodeURIComponent(value) })
        }
    })

    return apiGet(`/api/analysis/users?filter=${JSON.stringify(arr)}&date=${date}&year=${chartYear}`, null, 'safelink')
}


export function getUserBaseDeviceAPI(filters = [], date = "", chartYear = "") {
    let arr = []
    filters?.forEach((filter) => {
        for (const [key, value] of Object.entries(filter)) {
            arr.push({ key, value: encodeURIComponent(value) })
        }
    })
    return apiGet(`/api/analysis/deviceusers?filter=${JSON.stringify(arr)}&date=${date}&year=${chartYear}`, null, 'safelink')
}

export function getUserWithWeeklyReportsAPI(filters = [], date = "",chartYear="") {
    let arr = []
    filters?.forEach((filter) => {
        for (const [key, value] of Object.entries(filter)) {
            arr.push({ key, value: encodeURIComponent(value) })
        }
    })
    return apiGet(`/api/analysis/weeklyreportusers?filter=${JSON.stringify(arr)}&date=${date}&year=${chartYear}`, null, 'safelink')
}

export function getAutoSyncUserDataAPI(filters = [], date = "",chartYear="") {
    let arr = []
    filters?.forEach((filter) => {
        for (const [key, value] of Object.entries(filter)) {
            arr.push({ key, value: encodeURIComponent(value) })
        }
    })
    return apiGet(`/api/analysis/autosyncusers?filter=${JSON.stringify(arr)}&date=${date}&year=${chartYear}`, null, 'safelink')
}

export function getUnsubscribeUserDataAPI(filters = [], date = "",chartYear="") {
    let arr = []
    filters?.forEach((filter) => {
        for (const [key, value] of Object.entries(filter)) {
            arr.push({ key, value: encodeURIComponent(value) })
        }
    })
    return apiGet(`/api/analysis/unsubscribedusers?filter=${JSON.stringify(arr)}&date=${date}&year=${chartYear}`, null, 'safelink')
}

export function getUserWithUnsubscribeWeeklyReportsAPI(filters = [], date = "") {
    let arr = []
    filters?.forEach((filter) => {
        for (const [key, value] of Object.entries(filter)) {
            arr.push({ key, value: encodeURIComponent(value) })
        }
    })
    return apiGet(`/api/analysis/unsubscribedweeklyreportusers?filter=${JSON.stringify(arr)}&date=${date}`, null, 'safelink')
}


export function getUserSyncDataWithFiltersAPI(deviceType = "", date = "",chartYear="") {
    return apiGet(`/api/analysis/synchedData?deviceType=${deviceType}&date=${date}&year=${chartYear}`, null, 'safelink')
}

export function getAverageBatteryLifeAPI(filters = [], year = {},chartYear="") {
    let arr = []
    if (Object.values(year).length > 0) {
        if (year.type !== 'between') {
            delete year.value1
        }
        arr = [year]
    }
    return apiGet(`/api/analysis/averagebatterylife?filter=${JSON.stringify(arr)}&year=${chartYear}`, null, 'safelink')
}

export function getWeeklyReportsAPI(filters = [], date = "") {
    let arr = []
    filters?.forEach((filter) => {
        for (const [key, value] of Object.entries(filter)) {
            arr.push({ key, value: encodeURIComponent(value) })
        }
    })
    return apiGet(`/api/analysis/weeklyreports?filter=${JSON.stringify(arr)}&date=${date}`, null, 'safelink')
}

export function getLocationPreferenceAPI(deviceType = "", date = "",chartYear="") {
    return apiGet(`/api/analysis/locationprefernce?deviceType=${deviceType}&date=${date}&year=${chartYear}`, null, 'safelink')
}

export function getVersionListAPI() {
    return apiGet(`/api/analysis/appversionlist`, null, 'safelink')
}



