import { isEmpty } from "lodash";
import { scrollIntoView } from "../../utils";

export function validateEditDevice(data) {
    let errors = {};
    let isError = false

    const { tariffDetail = {}, registeredAddress = {}, deviceDetail = {} } = data


    if (!deviceDetail?.impulse_rate) {
        errors.impulse_rate = "Impulse Rate is required";

        if (!isError) {
            isError = true;
            scrollIntoView('agency_impulse_rate')
        }
    }

    if (deviceDetail.impulse_rate < 0) {
        errors.impulse_rate = "Impulse rate should be greater than 0"
        if (!isError) {
            isError = true;
            scrollIntoView('agency_impulse_rate')
        }
    }

    if (deviceDetail?.impulse_rate?.length && deviceDetail?.impulse_rate_type === "Wh/imp") {
        // if (1000 % deviceDetail?.impulse_rate != 0) {
        //     errors.impulse_rate = "Please enter a valid impulse rate";
        //     if (!isError) {
        //         isError = true;
        //         scrollIntoView('agency_impulse_rate')
        //     }
        // }


        let val = 1000 / deviceDetail?.impulse_rate

        if (val.toString().includes(".")) {
            errors.impulse_rate = "Please enter a valid impulse rate";
            if (!isError) {
                isError = true;
                scrollIntoView('agency_impulse_rate')
            }
        }
    }

    if (!registeredAddress?.property_name) {
        errors.property_name = "Property Name is required";

        if (!isError) {
            isError = true;
            scrollIntoView('agency_property_name')
        }
    }

    if (tariffDetail?.discount_type === "value") {
        if (!tariffDetail?.isFlatRate) {
            for (let i = 0; i < tariffDetail?.tariffPlan?.length; i++) {
                if (tariffDetail?.tariffPlan[i]?.unit_charge < tariffDetail?.discountPercent) {
                    errors.discountPercent = "Discount value must be less than unit charge"

                    if (!isError) {
                        isError = true;
                        scrollIntoView('discountPercent')
                    }
                }
            }
        }
        if (tariffDetail?.isFlatRate) {
            if (tariffDetail?.costPerKwh < tariffDetail?.discountPercent) {
                errors.discountPercent = "Discount value must be less than unit charge"

                if (!isError) {
                    isError = true;
                    scrollIntoView('discountPercent')
                }
            }
        }
    }


    // if (!registeredAddress?.street) {
    //     errors.street = "Street is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('agency_street')
    //     }
    // }

    // if (!registeredAddress?.street_number) {
    //     errors.street_number = "Street Number is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('agency_street_number')
    //     }
    // }

    // if (!registeredAddress?.street_type?.value) {
    //     errors.street_type = "Street Type is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('agency_street_type')
    //     }
    // }

    // if (!registeredAddress?.city) {
    //     errors.city = "Town/Suburb is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('agency_city')
    //     }
    // }

    // if (!registeredAddress?.state?.value) {
    //     errors.state = "State is required";

    //     if (!isError) {
    //         isError = true;
    //         scrollIntoView('agency_state')
    //     }
    // }

    if (!registeredAddress?.postal_code) {
        errors.postal_code = "Postcode is required";

        if (!isError) {
            isError = true;
            scrollIntoView('agency_postal_code')
        }
    }

    if ((registeredAddress?.postal_code) && ((registeredAddress.postal_code.length < 3) || (registeredAddress.postal_code.length > 4))) {
        errors.postal_code = "Valid postal code is required"

        if (!isError) {
            isError = true;
            scrollIntoView('agency_postal_code')
        }
    }

    if (!tariffDetail?.selectedElectricityDistributor?.value) {
        errors.selectedElectricityDistributor = "Electricity Distributor is required"

        if (!isError) {
            isError = true;
            scrollIntoView('agency_selectedElectricityDistributor')
        }
    }

    if (!tariffDetail?.seletedElectricityRetailer?.value) {
        errors.seletedElectricityRetailer = "Electricity Retailer is required"

        if (!isError) {
            isError = true;
            scrollIntoView('agency_seletedElectricityRetailer')
        }
    }

    if (!tariffDetail?.selectedTarrifStructure?.value) {
        errors.selectedTarrifStructure = "Tariff Structure is required"

        if (!isError) {
            isError = true;
            scrollIntoView('agency_selectedTarrifStructure')
        }
    }

    if (!tariffDetail?.isFlatRate) {
        let tariffPlanError = []
        tariffDetail?.tariffPlan?.length && tariffDetail.tariffPlan.map((val, index) => {
            if (!val?.unit_charge?.toString()) {
                tariffPlanError[index] = "Cost is required"
            }
        })
        if (tariffPlanError.length) {
            errors = { ...errors, tariffPlanError }

            if (!isError) {
                isError = true;
                scrollIntoView('agency_tariffPlanCost')
            }
        }
    }

    if (tariffDetail?.isFlatRate) {
        if (!tariffDetail?.costPerKwh?.toString()) {
            errors.costPerKwh = "Cost is required"

            if (!isError) {
                isError = true;
                scrollIntoView('agency_cost_per_kwh')
            }
        }
    }

    return {
        isValid: isEmpty(errors),
        errors
    };
}
