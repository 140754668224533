import {
    getAllWeeklyReportsAPI,
} from './apis'
import *  as commonActions from "../../common/actions"
import { errorMessage } from '../../../utils'

export const GET_ALL_WEEKLY_REPORTS_REQUEST = 'GET_ALL_WEEKLY_REPORTS_REQUEST';
export const GET_ALL_WEEKLY_REPORTS_SUCCESS = 'GET_ALL_WEEKLY_REPORTS_SUCCESS';
export const GET_ALL_WEEKLY_REPORTS_FAILED = 'GET_ALL_WEEKLY_REPORTS_FAILED';

export const getAllWeeklyReports = (filters) => dispatch =>
    new Promise((resolve, reject) => {
        dispatch({
            type: GET_ALL_WEEKLY_REPORTS_REQUEST
        })
        getAllWeeklyReportsAPI(filters).then(res => {
            const { info = {} } = res
            dispatch({
                type: GET_ALL_WEEKLY_REPORTS_SUCCESS,
                payload: info
            })
            return resolve(res)
        }).catch(err => {
            dispatch({
                type: GET_ALL_WEEKLY_REPORTS_FAILED
            })
            dispatch(commonActions.openSnackbarsState({ message: errorMessage(err), messageType: 'error' }))
            return reject(err)
        })
    })

export const CLEAR_ALL_WEEKLY_REPORTS_DATA = 'CLEAR_ALL_WEEKLY_REPORTS_DATA';

export const clearStoreForWeeklyReports = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALL_WEEKLY_REPORTS_DATA
        })
    }
}