import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';


export default function CustomDialog(props) {

    const { children, customFunction = () => { }, maxWidth, className = "" } = props;

    const [open, setModalOpen] = useState(true)


    function handleClose(event, reason) {
        // if (reason === 'backdropClick') {
        //     return
        // }

        setModalOpen(false)
        customFunction();
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth={maxWidth}
                className={className}
                fullWidth
            >
                {/* <IconButton onClick={handleClose} className="close">
                            <CloseIcon />
                        </IconButton> */}
                <DialogContent className="dailogBox" style={{ marginBottom: 20 }}>
                    {children}
                </DialogContent>
            </Dialog>
        </div>
    );
}
