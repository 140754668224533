import { apiGet, apiPost, apiPut, apiDelete, getDeviceType } from '../../../utils';


export function getAllPartnersAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value?.length) continue
        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/partner/all?filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}

export function getPartnerAPI(id) {
    return apiGet(`/api/agency/get/${id}`)
}


export function getPartnerDevicesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/partner/devices?partner_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}

export function getPartnerAgentsForTableAPI(filters) {
    let arr = []
    for (const [key, value] of Object.entries(filters)) {
        if (key === "isOpen") continue
        if (typeof value !== "number" && !value.length) continue
        if (key === "id") continue
        if (["status"].includes(key)) {
            arr.push({ key, value, type: 'multi' })
        } else {
            arr.push({ key, value: encodeURIComponent(value), type: "single" })
        }
    }
    return apiGet(`/api/partner/agents?partner_id=${filters.id}&filter=${JSON.stringify(arr)}&deviceType=${getDeviceType()}`)
}

export function getPartnerAgenciesForTableAPI(filters) {
    let { page = 1, limit = 25, orderBy = "desc", sortBy = 'created_at', id = '' } = filters || {}
    return apiGet(`/api/partner/agencies?partner_id=${id}&page=${page}&limit=${limit}&orderBy=${orderBy}&sortBy=${sortBy}&deviceType=${getDeviceType()}`)
}
