import { isEmpty } from "lodash"
import { urlPattern } from "../../constant"

export const SliderValidation = (data) => {
    let errors = {}
    if (!data?.name) {
        errors.name = 'Name is required'
    }
    if (data?.link && !urlPattern.test(data?.link)) {
        errors.link = 'Link is invalid.Enter valid link'
    }
    if (data?.fileToUpload?.length === 0) {
        errors.fileToUpload = 'Image is required'
    }

    return {
        isValid: isEmpty(errors),
        errors,
    }
}