import * as actionsTypes from './actions';

const initalState = {
    isLoading: false,
    deviceHistoryDetails: {}
}


export default function (state = { ...initalState }, action) {
    switch (action.type) {

        case actionsTypes.GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_REQUEST:
            return { ...state, isLoading: true }

        case actionsTypes.GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_SUCCESS:
            return { ...state, isLoading: false, deviceHistoryDetails: action.payload }

        case actionsTypes.GET_ELECTRICIAN_AGENT_DEVICE_HISTORY_DETAILS_FAILED:
            return { ...state, isLoading: false }

        case actionsTypes.CLEAR_STORE_FOR_COMMON_PAGES:
            return { ...state, ...initalState }

        default:
            return state
    }
}

